import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: string | null = null;

const breakdownCategorySlice = createSlice({
	name: 'breakdown-category',
	initialState,
	reducers: {
		setBreakdownCategory(state, action: PayloadAction<string>) {
			//@ts-ignore
			state = action.payload;
			return state;
		},
		unsetBreakdownCategory(state) {
			state = null;
			return state;
		},
	},
});

export const { setBreakdownCategory, unsetBreakdownCategory } =
	breakdownCategorySlice.actions;
export default breakdownCategorySlice.reducer;
