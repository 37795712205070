import { Typography } from '@mui/joy';
import React from 'react';

interface Props {
	children: React.ReactNode;
	sx?: any;
}

const SubHeader: React.FC<Props> = ({ children, sx }) => {
	return (
		<Typography
			fontWeight='lg'
			fontSize={{ xs: '18px', sm: '20px' }}
			sx={{
				color: 'kabinit.main',
				marginBottom: '40px',
				textAlign: { xs: 'center', sm: 'left' },
				...sx,
			}}
		>
			{children}
		</Typography>
	);
};

export default SubHeader;
