import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { FixMeLater } from '../../constants/FixMeLater';
import { RootState } from '../store';

export const ceChecklistWidgetAdapter = createEntityAdapter({
	selectId: (checklistItem: FixMeLater) => checklistItem._id,
});

const initialState = ceChecklistWidgetAdapter.getInitialState();

const ceChecklistWidgetSlice = createSlice({
	name: 'ceChecklistWidgetAdapter',
	initialState,
	reducers: {
		getChecklistItems: ceChecklistWidgetAdapter.setAll,
		addChecklistItem: ceChecklistWidgetAdapter.addOne,
		upsertChecklistItem: ceChecklistWidgetAdapter.upsertOne,
		removeChecklistItem: ceChecklistWidgetAdapter.removeOne,
		resetChecklistItems: ceChecklistWidgetAdapter.removeAll,
	},
});

export const {
	getChecklistItems,
	addChecklistItem,
	upsertChecklistItem,
	removeChecklistItem,
	resetChecklistItems,
} = ceChecklistWidgetSlice.actions;
export default ceChecklistWidgetSlice.reducer;

export const {
	selectById: selectChecklistItemById,
	selectIds: selectChecklistItemIds,
	selectEntities: selectChecklistItemEntities,
	selectAll: selectAllChecklist,
	selectTotal: selectTotalChecklist,
} = ceChecklistWidgetAdapter.getSelectors(
	(state: RootState) => state.ceChecklistWidget
);
