import { Typography } from '@mui/joy';
import { Box, Container, Stack } from '@mui/material';
import React from 'react';

interface Props {
	mb?: any;
}

const WebIntro: React.FC<Props> = ({ mb }) => {
	return (
		<Box sx={{ backgroundColor: 'kabinit.sidebarBg' }}>
			<Container maxWidth='md'>
				<Stack
					gap={3}
					sx={{
						py: { xs: 10, sm: 18 },
						px: 7,
						textAlign: 'center',
						mb,
					}}
				>
					<Typography fontSize={{ xs: '14px', sm: '21px' }}>
						근데 그럼.. 프리프로덕션에서는요..?
					</Typography>
					<Typography
						fontSize={{ xs: '22px', sm: '27px' }}
						fontWeight='lg'
						sx={{
							display: { xs: 'none', sm: 'flex' },
							justifyContent: 'center',
						}}
					>
						여러 파일들을 유지하고 공유하시느라 힘드셨죠?
					</Typography>
					<Typography
						fontSize={{ xs: '22px', sm: '27px' }}
						fontWeight='lg'
						sx={{ display: { xs: 'flex', sm: 'none' } }}
					>
						여러 파일들을 유지하고
						<br />
						공유하시느라 힘드셨죠?
					</Typography>
				</Stack>
			</Container>
		</Box>
	);
};

export default WebIntro;
