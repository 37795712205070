import { Container, Grid } from '@mui/material';
import React from 'react';
import image from '../assets/svgs/image_3.svg';

import FeatureHeaderStack from '../components/FeatureHeaderStack';
import SubHeader from '../components/SubHeader';

interface Props {
	mb?: any;
}

const WebFeatures: React.FC<Props> = ({ mb }) => {
	return (
		<Container maxWidth='md' sx={{ mb }}>
			<SubHeader sx={{ px: 7, mb: 5 }}>캐비닛 웹 서비스</SubHeader>
			<Grid container sx={{ alignItems: 'center' }}>
				<Grid item xs={12} sm={6}>
					<FeatureHeaderStack
						textArr={['촬영 준비도', '한 곳에서', '진행해보세요!']}
						sx={{ px: 7, display: { xs: 'none', sm: 'flex' } }}
					/>
					<FeatureHeaderStack
						textArr={['촬영 준비도 한 곳에서', '진행해보세요!']}
						sx={{ px: 7, display: { xs: 'relative', sm: 'none' } }}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					sx={{
						display: 'flex',
						justifyContent: { xs: 'center', sm: 'flex-end' },
						px: { xs: 10, sm: 0 },
						pr: { xs: 10, sm: '8%' },
						mt: { xs: 6, sm: 1 },
					}}
				>
					<img
						style={{
							flexBasis: 'calc(100% - 90px)',
							maxWidth: 'calc(100% - 90px)',
							objectFit: 'contain',
						}}
						src={image}
						alt={'webservice-1'}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};

export default WebFeatures;
