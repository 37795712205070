import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Auth from '../features/Auth';
import InviteSignUp from '../features/InviteSignUp';
import PasswordReset from '../features/PasswordReset';
import FindEmail from '../features/FindAccountData/FindEmail';

import SignUp from '../features/SignUp';
import FindPassword from '../features/FindAccountData/FindPassword';
import LandingPage from '../landingpage';
import NotFoundPage from '../pages/NotFoundPage';
import MaintenancePage from '../pages/MaintenancePage';

const PublicRoutes = () => {
	return (
		<Routes>
			<Route path='/' element={<LandingPage />} />
			{/* <Route path='/auth' element={<Auth />} />
			<Route path='/signup' element={<SignUp />} />
			<Route path='/signup/:key' element={<SignUp />} />
			<Route path='/findemail' element={<FindEmail />} />
			<Route path='/findpassword' element={<FindPassword />} />
			<Route path='/invite/:inviteCode' element={<InviteSignUp />} />
			<Route
				path='/password-reset/:resetToken/:email'
				element={<PasswordReset />}
			/> */}
			<Route path='/notfound' element={<NotFoundPage />} />
			<Route path='*' element={<MaintenancePage />} />
		</Routes>
	);
};

export default PublicRoutes;
