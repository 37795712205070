import { checkSenderMatch } from '.';
import { store } from '../../redux/store';
import { upsertUserPermission } from '../../redux/user-permission/user-permission.slice';
import { ProjectEvents } from '../events/projects.events';

const dispatch = store.dispatch;

export const userListener = {
	message: (messageEvent: any) => {
		//NOTE: this will skip if the current user is sender
		if (
			checkSenderMatch(
				messageEvent.message.sender,
				store?.getState()?.auth?.user?._id
			)
		) {
			return;
		}

		if (
			messageEvent.message.event === ProjectEvents.CrewPermissionChanged
		) {
			dispatch(upsertUserPermission(messageEvent.message.data));
		}
	},
};
