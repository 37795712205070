import {
	createEntityAdapter,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { CategoryArray } from '../../features/BreakdownPdf/CategoryButton';
import { Breakdown, BreakdownItem } from '../../interface/episode.interface';
import { RootState } from '../store';

export const breakdownAdapter = createEntityAdapter({
	selectId: (breakdown: Breakdown) => breakdown._id,
	sortComparer: (a: Breakdown, b: Breakdown) =>
		CategoryArray.findIndex((c) => c.type === a.type) -
		CategoryArray.findIndex((c) => c.type === b.type),
});

const initialState = breakdownAdapter.getInitialState();

const breakdownSlice = createSlice({
	name: 'breakdowns',
	initialState,
	reducers: {
		setAllBreakdowns: breakdownAdapter.setAll,
		setBreakdown: breakdownAdapter.setOne,
		addBreakdown: breakdownAdapter.addOne,
		upsertBreakdown: breakdownAdapter.upsertOne,
		upsertBreakdowns: breakdownAdapter.upsertMany,
		removeBreakdown: breakdownAdapter.removeOne,
		resetBreakdowns: breakdownAdapter.removeAll,
		addBreakdownItem: (state, action) => {
			const { breakdownId, item } = action.payload;
			const breakdown = state.entities[breakdownId];
			breakdown?.items?.push(item);
		},
		updateBreakdownItem: (
			state,
			action: PayloadAction<{
				breakdownId: string;
				updatedItem: BreakdownItem;
			}>
		) => {
			const { breakdownId, updatedItem } = action.payload;
			const breakdown = state.entities[breakdownId];
			const itemIndex = breakdown?.items?.findIndex(
				(item) => item._id === updatedItem._id
			);
			if (itemIndex !== undefined && itemIndex !== -1) {
				breakdown?.items?.splice(itemIndex, 1, updatedItem);
			}
		},
		removeBreakdownItem: (
			state,
			action: PayloadAction<{
				breakdownId: string;
				deletedItemIds: string[];
			}>
		) => {
			const { breakdownId, deletedItemIds } = action.payload;
			const breakdown = state.entities[breakdownId];
			// filter out items
			if (breakdown) {
				breakdown.items = breakdown?.items?.filter(
					(item) => !deletedItemIds.includes(item._id)
				);
			}
		},
	},
});

export const {
	setAllBreakdowns,
	setBreakdown,
	addBreakdown,
	upsertBreakdown,
	upsertBreakdowns,
	removeBreakdown,
	resetBreakdowns,
	addBreakdownItem,
	updateBreakdownItem,
	removeBreakdownItem,
} = breakdownSlice.actions;
export default breakdownSlice.reducer;

export const {
	selectById: selectBreakdownById,
	selectIds: selectBreakdownIds,
	selectEntities: selectBreakdownEntities,
	selectAll: selectAllBreakdowns,
	selectTotal: selectTotalBreakdowns,
} = breakdownAdapter.getSelectors((state: RootState) => state.breakdowns);

export const selectBreakdownByType = (state: RootState, type: string) => {
	const breakdowns = selectAllBreakdowns(state);
	const breakdown = breakdowns.find((breakdown) => breakdown.type === type);
	return breakdown;
};

export const selectBreakdownItemsByType = (state: RootState, type: string) => {
	const breakdown = selectBreakdownByType(state, type);
	return breakdown?.items;
};

export const selectNotCreatedBreakdownTypes = (state: RootState) => {
	const breakdowns = selectAllBreakdowns(state);
	const breakdownTypes = breakdowns.map((breakdown) => breakdown.type);
	const notCreatedBreakdownTypes = CategoryArray.filter(
		(category) => !breakdownTypes.includes(category.type as any)
	);
	return notCreatedBreakdownTypes;
};
