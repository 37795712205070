import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { FixMeLater } from '../../constants/FixMeLater';
import { Character } from '../../interface/character.interface';
import { Timetable } from '../../interface/timetable.interface';
import { RootState } from '../store';

export const scenesAdapter = createEntityAdapter({
	selectId: (scene: FixMeLater) => scene._id,
	sortComparer: (a, b) => {
		if (a.sceneNo === b.sceneNo) {
			return a?.subSceneNo?.charCodeAt(0) - b?.subSceneNo?.charCodeAt(0);
		}
		return a.sceneNo - b.sceneNo;
	},
});

const initialState = scenesAdapter.getInitialState();

const scenesSlice = createSlice({
	name: 'scenes',
	initialState,
	reducers: {
		setAllScenes: scenesAdapter.setAll,
		setScene: scenesAdapter.setOne,
		addScene: scenesAdapter.addOne,
		upsertScene: scenesAdapter.upsertOne,
		upsertScenes: scenesAdapter.upsertMany,
		removeScene: scenesAdapter.removeOne,
		resetScenes: scenesAdapter.removeAll,

		addSceneToTimetable: (state, action) => {
			const sceneData = state.entities[action.payload.sceneId];
			sceneData.timetableAllocation.push(action.payload.timetable);
		},
		removeSceneFromTimetable: (state, action) => {
			const sceneData = state.entities[action.payload.sceneId];
			sceneData.timetableAllocation =
				sceneData.timetableAllocation.filter(
					(timetable: Timetable) =>
						timetable._id !== action.payload.timetableId
				);
		},
		addCharacterToScene: (state, action) => {
			const sceneData = state.entities[action.payload.sceneId];
			sceneData.characters.push(action.payload.characterData);
		},
		removeCharacterFromScene: (state, action) => {
			const sceneData = state.entities[action.payload.sceneId];
			sceneData.characters = sceneData.characters.filter(
				(character: Character) =>
					character._id !== action.payload.characterId
			);
		},
		removeCharacterFromAllScenes: (state, action) => {
			for (const index in state.ids) {
				let sceneId = state.ids[index];
				let sceneData = state.entities[sceneId];
				sceneData.characters = sceneData.characters.filter(
					(character: Character) => character._id !== action.payload
				);
			}
		},
		removeLocationFromAllScenes: (state, action) => {
			for (const index in state.ids) {
				let sceneId = state.ids[index];
				let sceneData = state.entities[sceneId];
				if (sceneData.location === action.payload) {
					sceneData.location = null;
				}
			}
		},
	},
});

export const {
	setAllScenes,
	setScene,
	addScene,
	upsertScene,
	upsertScenes,
	removeScene,
	resetScenes,
	addCharacterToScene,
	removeCharacterFromScene,
	removeCharacterFromAllScenes,
	removeLocationFromAllScenes,
	addSceneToTimetable,
	removeSceneFromTimetable,
} = scenesSlice.actions;
export default scenesSlice.reducer;

export const {
	selectById: selectSceneById,
	selectIds: selectSceneIds,
	selectEntities: selectSceneEntities,
	selectAll: selectAllScenes,
	selectTotal: selectTotalScenes,
} = scenesAdapter.getSelectors((state: RootState) => state.scenes);
