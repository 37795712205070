import { Typography } from '@mui/joy';
import React from 'react';

interface Props {
	children: React.ReactNode;
	sx?: any;
	left?: boolean;
}

const BlockHeader: React.FC<Props> = ({ children, sx, left }) => {
	return (
		<Typography
			fontWeight='xl'
			fontSize='23px'
			sx={{
				textAlign: { xs: 'left', sm: left ? 'left' : 'right' },
				...sx,
			}}
		>
			{children}
		</Typography>
	);
};

export default BlockHeader;
