import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project } from '../../interface/project.interface';
import { RootState } from '../store';

interface InviteProjectState {
	inviteProject: Project | undefined;
	dialogOpen: boolean;
	inviteCode: string | undefined;
}

const initialState: InviteProjectState = {
	inviteProject: undefined,
	dialogOpen: false,
	inviteCode: undefined,
};

const inviteProjectSlice = createSlice({
	name: 'invite-project',
	initialState,
	reducers: {
		getInviteProject(state, action: PayloadAction<Project>) {
			state.inviteProject = action.payload;
			return state;
		},
		openInviteProjectDialog(state) {
			state.dialogOpen = true;
			return state;
		},
		closeInviteProjectDialog(state) {
			state.dialogOpen = false;
			return state;
		},
		setInviteCode(state, action: PayloadAction<string>) {
			state.inviteCode = action.payload;
			return state;
		},
		unsetInviteCode(state) {
			state.inviteCode = undefined;
			return state;
		},
		resetProjectInviteState(state) {
			state.inviteProject = undefined;
			state.inviteCode = undefined;
			state.dialogOpen = false;
			return state;
		},
	},
});

export const {
	getInviteProject,
	openInviteProjectDialog,
	closeInviteProjectDialog,
	setInviteCode,
	unsetInviteCode,
	resetProjectInviteState,
} = inviteProjectSlice.actions;
export default inviteProjectSlice.reducer;

export const selectInviteProject = (state: RootState) =>
	state.inviteProject.inviteProject;

export const selectInviteProjectDialogOpen = (state: RootState) =>
	state.inviteProject.dialogOpen;

export const selectInviteCode = (state: RootState) =>
	state.inviteProject.inviteCode;
