import { Button, Typography } from '@mui/joy';
import React from 'react';

const registrationFormUrl = 'https://forms.gle/F4JFzDD9xCp151qj6';

const RegistrationButton = () => {
	return (
		<Button
			sx={{
				backgroundColor: '#1464FF',
				borderRadius: '12px',
				width: '190px',
				paddingY: ' 15px',
				marginX: '5px',
			}}
			onClick={() => window.open(registrationFormUrl, '_blank')}
		>
			<Typography textColor='white' fontWeight='xl' fontSize='20px'>
				얼리버드 신청하기
			</Typography>
		</Button>
	);
};

export default RegistrationButton;
