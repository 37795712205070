import { RootState } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../interface/auth.interface';
import { DropdownOption } from '../../interface/dropdown.interface';
import { Project } from '../../interface/project.interface';
import history from '../../utils/history';
import { kabinitApiCred } from '../../apis/index';
import { toast } from 'react-hot-toast';

interface AuthState {
	user: User | null;
	isSignedIn: boolean;
}

const initialState: AuthState = {
	user: null,
	isSignedIn: false,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		signIn(state, action: PayloadAction<User>) {
			state.user = action.payload;
			state.isSignedIn = true;
			return state;
		},
		signOut(state) {
			state = initialState;
			return state;
		},
		updateUser(state, action: PayloadAction<User>) {
			if (state.user) {
				state.user = Object.assign(state.user, action.payload);
			}
			return state;
		},
	},
});

export const onSilentRefresh = async () => {
	try {
		const expiryTime = process.env.REACT_APP_ACCESS_EXPIRY_TIME as string;
		const refreshTime = +expiryTime - 60000;
		setTimeout(() => {
			onSilentRefresh();
		}, refreshTime);

		await kabinitApiCred.post('/auth/refresh');
	} catch {
		await kabinitApiCred.post('/auth/forcesignout');
		// store.dispatch(signOut());
		toast.error(
			'일정 시간이 지나 로그아웃 되었습니다. 다시 로그인 해주세요.'
		);
		//TODO: sign out
	}
};

export const { signIn, signOut, updateUser } = authSlice.actions;
export default authSlice.reducer;

export const selectAuth = (state: RootState) => {
	return state.auth;
};
export const selectIsSignedIn = (state: RootState) => {
	return state.auth.isSignedIn;
};

export const selectUser = (state: RootState) => {
	return state.auth.user;
};
// export const selectUserBasic = (state: RootState) => {
// 	const userBasic = {
// 		_id: state.auth.user._id,
// 		firstName: state.auth.user.firstName,
// 		lastname: state.auth.user.lastName,
// 	};
// 	return userBasic;
// };
export const selectUserId = (state: RootState) => {
	if (state.auth.user) {
		return state.auth.user._id;
	}
	return null;
};
export const selectAvatar = (state: RootState) => {
	if (state.auth.user && state.auth.user.avatar) {
		return state.auth.user.avatar;
	}
	return null;
};

export const selectUserName = (state: RootState) => {
	if (state.auth.user) {
		const fullName = state.auth.user.lastName + state.auth.user.firstName;

		return fullName;
	}
	return null;
};

export const selectUserRecents = (state: RootState) => {
	let recents: DropdownOption[] = [];
	if (
		state.auth?.user?.recents &&
		state.auth?.user?.recents.length > 0 &&
		state.auth?.user?.recents[0].title
	) {
		recents = state.auth.user.recents.map((project: Project) => {
			return {
				label: project.title,
				onClick: () => {
					history.push(`/projects/${project._id}/scenes`);
				},
			};
		});
	}
	return recents;
};

export const selectUserDistinction = (state: RootState) => {
	if (state.auth.user) {
		return state.auth.user.userDistinction;
	}
	return null;
};

export const selectProjectLimit = (state: RootState) => {
	const projectCount = state.auth.user?.projects.length || 0;
	switch (state.auth.user?.userDistinction) {
		case 'closedbeta':
			return 3 - projectCount;
		case 'closedbeta-crew':
			return 0 - projectCount;
		case 'qa':
			return 1000 - projectCount;
		default:
			return 0;
	}
};
