import React from 'react';
import { Box, Typography } from '@mui/joy';
import { Stack } from '@mui/material';
import combinedImage from '../assets/svgs/image1_combined.svg';
import teamImage from '../assets/svgs/team.svg';
import RegistrationButton from '../components/RegistrationButton';

interface Props {
	mb: any;
}

const Intro: React.FC<Props> = ({ mb }) => {
	return (
		<Stack
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: { xs: '120px', sm: '150px' },
				mb,
			}}
		>
			<Typography
				fontWeight='lg'
				fontSize={{ xs: '19px', sm: '35px' }}
				sx={{ mb: '27px' }}
				textAlign='center'
			>
				앱으로 만나는 새로운 일일촬영계획표
			</Typography>
			<Typography
				fontWeight='xl'
				fontSize={{ xs: '28px', sm: '50px' }}
				textAlign='center'
			>
				촬영장을{' '}
				<Box component='span' sx={{ color: 'kabinit.main' }}>
					캐비닛
				</Box>
				에 담으세요
			</Typography>

			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '52px',
				}}
			>
				<RegistrationButton />
			</Box>
			<Box
				sx={{
					display: { xs: 'flex', sm: 'none' },
					justifyContent: 'center',
					mt: 15,
				}}
			>
				<img
					style={{
						display: 'flex',
						flexBasis: 'calc(100% - 250px)',
						maxWidth: 'calc(100% - 250px)',
						objectFit: 'contain',
					}}
					src={teamImage}
					alt={'1'}
				/>
			</Box>
			<Box
				sx={{
					display: { xs: 'none', sm: 'flex' },
					justifyContent: 'center',
				}}
			>
				<img
					style={{
						display: 'flex',
						flexBasis: 'calc(100% - 100px)',
						maxWidth: 'calc(100% - 100px)',
						objectFit: 'contain',
					}}
					src={combinedImage}
					alt={'1'}
				/>
			</Box>
		</Stack>
	);
};

export default Intro;
