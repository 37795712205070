import { Button, Typography } from '@mui/joy';
import { Box } from '@mui/material';
import React from 'react';
import CategoryCircle from './CategoryCircle';

export interface Category {
	categoryNo: string;
	categoryName: string;
	categoryNameShort?: string;
	type: string;
}

export const CategoryArray: Category[] = [
	{
		categoryNo: '1',
		categoryName: '미술 & 소품',
		categoryNameShort: '미술/소품',
		type: 'props',
	},
	{
		categoryNo: '2',
		categoryName: '의상',
		type: 'costume',
	},
	{
		categoryNo: '3',
		categoryName: '헤어 & 메이크업',
		categoryNameShort: '헤/메',
		type: 'makeup',
	},
	{
		categoryNo: '4',
		categoryName: '촬영',
		type: 'camera',
	},
	{
		categoryNo: '5',
		categoryName: '조명',
		type: 'electrical',
	},
	{
		categoryNo: '6',
		categoryName: '그립',
		type: 'grip',
	},

	{
		categoryNo: '7',
		categoryName: '특효',
		type: 'sfx',
	},
	{
		categoryNo: '8',
		categoryName: '스턴트',
		type: 'stunts',
	},
	{
		categoryNo: '9',
		categoryName: '보출',
		type: 'bg',
	},
	{
		categoryNo: '0',
		categoryName: '기타',
		type: 'etc',
	},
];

interface Props {
	index: number;
	currentCategory: string | null;
	onClick: (newCategory: string | null) => void;
}

const CategoryButton: React.FC<Props> = ({
	index,
	currentCategory,
	onClick,
}) => {
	const isCurrentCategory =
		currentCategory === CategoryArray[index].categoryNo;

	const handleClick = () => {
		// if isCurrentCategory, set categoryNo to null
		// else set categoryNo to the categoryNo of the button
		onClick(isCurrentCategory ? null : CategoryArray[index].categoryNo);
	};
	return (
		<Button
			value={CategoryArray[index].categoryNo}
			onClick={handleClick}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				width: '56px',
				height: '52px',
				py: 0.5,
				px: 0,
				backgroundColor: isCurrentCategory
					? `BDcolor.${CategoryArray[index].categoryNo}.plainColor`
					: `white`,
				color: isCurrentCategory
					? `BDcolor.${CategoryArray[index].categoryNo}.activeText`
					: 'grey.200',
				borderRadius: '8px',
				border: 'none',
				'&:hover': {
					backgroundColor: `BDcolor.${CategoryArray[index].categoryNo}.hoverBg`,
					color: `BDcolor.${CategoryArray[index].categoryNo}.hoverText`,
				},
				'&:active': {
					backgroundColor: `BDcolor.${CategoryArray[index].categoryNo}.plainColor`,
					color: `BDcolor.${CategoryArray[index].categoryNo}.activeText`,
				},
			}}
		>
			<CategoryCircle
				index={index}
				isCurrentCategory={isCurrentCategory}
			/>
			<Typography
				fontSize='sm'
				fontWeight='lg'
				sx={{ color: 'inherit', mb: -0.25 }}
			>
				{CategoryArray[index].categoryName}
			</Typography>
			<Typography fontSize='xs' fontWeight='xl' sx={{ color: 'inherit' }}>
				{CategoryArray[index].categoryNo}
			</Typography>
		</Button>
	);
};

export default CategoryButton;
