import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const patchNoteAdapter = createEntityAdapter({
	selectId: (patchNote: any) => patchNote._id,
});

const initialState = patchNoteAdapter.getInitialState();

const PatchNoteSlice = createSlice({
	name: 'patch-note',
	initialState,
	reducers: {
		getPatchNotes: patchNoteAdapter.setAll,
		addPatchNote: patchNoteAdapter.addOne,
		upsertPatchNote: patchNoteAdapter.upsertOne,
		removePatchNote: patchNoteAdapter.removeOne,
		resetPatchNotes: patchNoteAdapter.removeAll,
	},
});

export const {
	getPatchNotes,
	addPatchNote,
	upsertPatchNote,
	removePatchNote,
	resetPatchNotes,
} = PatchNoteSlice.actions;
export default PatchNoteSlice.reducer;

export const {
	selectById: selectPatchNoteById,
	selectIds: selectPatchNoteIds,
	selectEntities: selectPatchNoteEntities,
	selectAll: selectAllPatchNotes,
	selectTotal: selectTotalPatchNotes,
} = patchNoteAdapter.getSelectors((state: RootState) => state.patchNote);

export const selectLatestVersion = (state: RootState) => {
	const patchNotes = selectAllPatchNotes(state);

	return patchNotes[0];
};
