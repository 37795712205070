import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: string = '';

const currentSceneSlice = createSlice({
	name: 'current-scene',
	initialState,
	reducers: {
		setCurrentScene(state, action: PayloadAction<string>) {
			state = action.payload;
			return state;
		},
		unsetCurrentScene(state) {
			state = '';
			return state;
		},
	},
});

export const { setCurrentScene, unsetCurrentScene } = currentSceneSlice.actions;
export default currentSceneSlice.reducer;

export const selectCurrentSceneId = (state: RootState) => {
	return state.currentScene;
};

export const selectIsCurrentScene = (state: RootState, sceneId: string) => {
	return state.currentScene === sceneId;
};
