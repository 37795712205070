import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Project } from '../../interface/project.interface';
import { RootState } from '../store';

export const projectAdapter = createEntityAdapter({
	selectId: (project: Project) => project._id,
});

const initialState = projectAdapter.getInitialState();

const projectsSlice = createSlice({
	name: 'projects',
	initialState,
	reducers: {
		getProjects: projectAdapter.setAll,
		getProject: projectAdapter.setOne,
		createProject: projectAdapter.addOne,
		upsertProject: projectAdapter.upsertOne,
		deleteProject: projectAdapter.removeOne,
		resetProjects: projectAdapter.removeAll,
	},
});

export const {
	getProjects,
	getProject,
	createProject,
	upsertProject,
	deleteProject,
	resetProjects,
} = projectsSlice.actions;
export default projectsSlice.reducer;

export const {
	selectById: selectProjectById,
	selectIds: selectProjectIds,
	selectEntities: selectProjectEntities,
	selectAll: selectAllProjects,
	selectTotal: selectTotalProjects,
} = projectAdapter.getSelectors((state: RootState) => state.projects);

export const selectIsProjectAdmin = (
	state: RootState,
	projectId: string,
	userId: string
) => {
	const project = selectProjectById(state, projectId);
	return project?.admin?._id === userId;
};

export const selectProjectLogo = (state: RootState, projectId: string) => {
	const project = selectProjectById(state, projectId);
	return project?.logo;
};
