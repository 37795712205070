import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: string = '';

const currentCrew = createSlice({
	name: 'current-crew',
	initialState,
	reducers: {
		dropCurrentCrew(state) {
			state = initialState;
			return state;
		},
		setCurrentCrew(state, action: PayloadAction<string>) {
			state = action.payload;
			return state;
		},
	},
});

export const { dropCurrentCrew, setCurrentCrew } = currentCrew.actions;
export default currentCrew.reducer;

export const selectCurrentCrewId = (state: RootState) => state.currentCrew;
