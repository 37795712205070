import toast from 'react-hot-toast';
import { apiSlice } from '../api/api.slice';
import { SignInForm, SignUpForm } from '../../interface/forms.interface';
import history from '../../utils/history';
import { signIn, signOut, updateUser } from './auth.slice';
import { asyncToast } from '../../utils/toast';
import {
	InviteSignInForm,
	InviteSignupForm,
} from '../../interface/auth.interface';
import { kabinitApiCred } from '../../apis';
import { onSilentRefresh } from './auth.actions';

const authApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		refresh: build.query({
			query: () => ({
				url: '/auth/refresh',
				method: 'POST',
				withCredentials: true,
			}),

			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(updateUser(data.data));
					dispatch(onSilentRefresh());
				} catch (err: any) {
					dispatch(signOut());
					await kabinitApiCred.post('auth/forcesignout');
					toast.error(
						'일정 시간이 지나 로그아웃 되었습니다. 다시 로그인 해주세요.'
					);
				}
			},
		}),

		signUp: build.mutation({
			query: (body: SignUpForm) => ({
				url: '/auth/signup',
				method: 'POST',
				withCredentials: true,
				body,
			}),

			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					await asyncToast(
						queryFulfilled,
						'계정생성 성공!',
						'계정생성 중'
					);

					history.push('/auth');
				} catch (err: any) {
					toast.error(err.error.data.message);
				}
			},
		}),
		signIn: build.mutation({
			query: (body: SignInForm) => ({
				url: '/auth/signin',
				method: 'POST',
				withCredentials: true,
				body,
			}),

			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await asyncToast(
						queryFulfilled,
						'로그인 성공!',
						'로그인 중'
					);

					// update state
					dispatch(signIn(data.data));
					// call onSilentRefresh
					history.push('/');
				} catch (err: any) {
					toast.error(err.error.data.message);
				}
			},
		}),
		inviteSignUp: build.mutation({
			query: (body: InviteSignupForm) => ({
				url: '/user-crew-requests/invite/signup',
				method: 'POST',
				withCredentials: true,
				body,
			}),

			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await asyncToast(
						queryFulfilled,
						'계정생성 성공!',
						'계정생성 중'
					);
					toast.success('참여 신청 완료!');

					// update state
					dispatch(signIn(data.data));
					history.push('/');
				} catch (err: any) {
					toast.error(err.error.data.message);
				}
			},
		}),
		inviteSignIn: build.mutation({
			query: (body: InviteSignInForm) => ({
				url: '/user-crew-requests/invite/signin',
				method: 'POST',
				withCredentials: true,
				body,
			}),

			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await asyncToast(
						queryFulfilled,
						'로그인 성공!',
						'로그인 중'
					);
					toast.success('참여 신청 완료!');

					// update state
					dispatch(signIn(data.data));
					history.push('/');
				} catch (err: any) {
					toast.error(err.error.data.message);
				}
			},
		}),
		signOut: build.mutation({
			query: () => ({
				url: '/auth/signout',
				method: 'POST',
				withCredentials: true,
			}),

			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					await asyncToast(queryFulfilled, null, '로그아웃 성공!');
					// update state
					// dispatch({ type: 'store/reset' });
					dispatch(signOut());
				} catch (err: any) {
					toast.error(err.error.data.message);
				}
			},
		}),
		changePassword: build.mutation({
			query: (body: {
				currentPassword: string;
				newPassword: string;
			}) => ({
				url: '/auth/password',
				method: 'PATCH',
				withCredentials: true,
				body,
			}),

			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					await asyncToast(
						queryFulfilled,
						'비밀번호 변경 성공!',
						null
					);
				} catch (err: any) {
					toast.error(err.error.data.message);
				}
			},
		}),
		deleteAccount: build.mutation({
			query: () => ({
				url: '/auth/user',
				method: 'DELETE',
				withCredentials: true,
			}),

			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					await asyncToast(
						queryFulfilled,
						'탈퇴 성공',
						'계정 삭제 중...'
					);
					dispatch(signOut());
					history.push('/');
				} catch (err: any) {
					toast.error(err.error.data.message);
				}
			},
		}),
		submitKey: build.mutation({
			query: (key: string) => ({
				url: `/auth/key/${key}`,
				method: 'POST',
				withCredentials: true,
			}),

			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await asyncToast(
						queryFulfilled,
						'코드 사용 성공!'
					);
					dispatch(updateUser(data.data));
					history.push('/');
				} catch (err: any) {
					toast.error(err.error.data.message);
				}
			},
		}),
	}),

	overrideExisting: false,
});

export const {
	useInviteSignUpMutation,
	useSignInMutation,
	useSignUpMutation,
	useSignOutMutation,
	useInviteSignInMutation,
	useRefreshQuery,
	useChangePasswordMutation,
	useDeleteAccountMutation,
	useSubmitKeyMutation,
} = authApi;
