import { Typography } from '@mui/joy';
import { Grid } from '@mui/material';
import React from 'react';
import BlockHeader from './BlockHeader';

// 여기에 기능들 블럭 하나씩

// Props
// header: 파란 글씨
// lineOne: 큰 글씨 첫 줄
// lineTwo: 큰 글씨 둘째 줄
// image: {src: 경로, alt: 이미지 설명/글로 나로 표현 ex: "realtime-feature"}
// left: true || false -> true면 이미지가 왼쪽으로

interface Props {
	header: string;
	lineOne: string;
	lineTwo: string;
	image: { src: string; alt: string };
	left?: boolean;
}

const FeatureBlock: React.FC<Props> = ({
	header,
	lineOne,
	lineTwo,
	image,
	left,
}) => {
	const renderImageRight = () => {
		return (
			<Grid container sx={{ mb: 12 }} rowSpacing={{ xs: 5, sm: 0 }}>
				<Grid
					item
					xs={12}
					sm={6}
					sx={{
						display: 'flex',
						flexDirection: 'column',
						mb: { xs: 3, sm: 0 },
					}}
				>
					<Typography
						fontSize='18px'
						fontWeight='xl'
						textColor='kabinit.main'
						sx={{ mb: { xs: 1, sm: 4 } }}
					>
						{header}
					</Typography>
					<BlockHeader sx={{ mb: { xs: 0, sm: 2 } }} left={true}>
						{lineOne}
					</BlockHeader>
					<BlockHeader left={true}>{lineTwo}</BlockHeader>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					sx={{
						display: 'flex',
						width: '100%',
						alignItems: 'center',
						justifyContent: { xs: 'center', sm: 'flex-end' },
					}}
				>
					<img
						style={{
							flexBasis: 'calc(100% - 40px)',
							maxWidth: 'calc(100% - 40px)',
							flex: 1,
							filter: 'drop-shadow(-10px 10px 10px #a4a7b0)',
							objectFit: 'contain',
						}}
						src={image.src}
						alt={image.alt}
					/>
				</Grid>
			</Grid>
		);
	};
	const renderImageLeft = () => {
		return (
			<Grid container sx={{ mb: 12 }} rowSpacing={{ xs: 5, sm: 0 }}>
				<Grid
					item
					xs={12}
					sm={6}
					sx={{
						display: { xs: 'none', sm: 'flex' },
						justifyContent: { xs: 'center', sm: 'flex-start' },
					}}
				>
					<img
						style={{
							flexBasis: 'calc(100% - 40px)',
							maxWidth: 'calc(100% - 40px)',
							filter: 'drop-shadow(-10px 10px 10px #a4a7b0)',
							objectFit: 'contain',
						}}
						src={image.src}
						alt={image.alt}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					sx={{
						display: 'flex',
						flexDirection: 'column',
						mb: { xs: 3, sm: 0 },
					}}
				>
					<Typography
						fontSize='18px'
						fontWeight='xl'
						textColor='kabinit.main'
						sx={{
							mb: { xs: 1, sm: 4 },
							textAlign: { xs: 'left', sm: 'right' },
						}}
					>
						{header}
					</Typography>
					<BlockHeader
						sx={{
							mb: { xs: 0, sm: 2 },
						}}
					>
						{lineOne}
					</BlockHeader>
					<BlockHeader>{lineTwo}</BlockHeader>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					sx={{ display: { xs: 'flex', sm: 'none' } }}
				>
					<img
						style={{
							flexBasis: 'calc(100% - 40px)',
							maxWidth: 'calc(100% - 40px)',
							filter: 'drop-shadow(-10px 10px 10px #a4a7b0)',
							objectFit: 'contain',
						}}
						src={image.src}
						alt={image.alt}
					/>
				</Grid>
			</Grid>
		);
	};

	return left ? renderImageLeft() : renderImageRight();
};

export default FeatureBlock;
