import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { FixMeLater } from '../../constants/FixMeLater';
import { RootState } from '../store';

export const userRequestsAdapter = createEntityAdapter({
	selectId: (project: FixMeLater) => project._id,
});

const initialState = userRequestsAdapter.getInitialState();

const userRequestsSlice = createSlice({
	name: 'user-requests',
	initialState,
	reducers: {
		getUserRequests: userRequestsAdapter.setAll,
		addUserRequest: userRequestsAdapter.addOne,
		upsertUserRequest: userRequestsAdapter.upsertOne,
		deleteUserRequest: userRequestsAdapter.removeOne,
		resetUserRequests: userRequestsAdapter.removeAll,
	},
});

export const {
	getUserRequests,
	addUserRequest,
	upsertUserRequest,
	deleteUserRequest,
	resetUserRequests,
} = userRequestsSlice.actions;
export default userRequestsSlice.reducer;

export const {
	selectById: selectUserRequestById,
	selectIds: selectUserRequestIds,
	selectEntities: selectUserRequestEntities,
	selectAll: selectAllUserRequests,
	selectTotal: selectTotalUserRequests,
} = userRequestsAdapter.getSelectors((state: RootState) => state.userRequests);
