import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { FixMeLater } from '../../constants/FixMeLater';
import { RootState } from '../store';

export const timetablesAdapter = createEntityAdapter({
	selectId: (timetable: FixMeLater) => timetable._id,
	sortComparer: (a, b) => {
		if (!a.tableDate) {
			return 1;
		}
		if (!b.tableDate) {
			return -1;
		}
		const aDate = DateTime.fromISO(a.tableDate);
		const bDate = DateTime.fromISO(b.tableDate);
		const diff = aDate.diff(bDate);
		if (diff.milliseconds > 0) {
			return 1;
		} else {
			return -1;
		}
	},
});

const initialState = timetablesAdapter.getInitialState();

const timetablesSlice = createSlice({
	name: 'timetables',
	initialState,
	reducers: {
		getTimetables: timetablesAdapter.setAll,
		createTimetable: timetablesAdapter.addOne,
		upsertTimetable: timetablesAdapter.upsertOne,
		deleteTimetable: timetablesAdapter.removeOne,
		resetTimetables: timetablesAdapter.removeAll,
	},
});

export const {
	getTimetables,
	createTimetable,
	upsertTimetable,
	deleteTimetable,
	resetTimetables,
} = timetablesSlice.actions;
export default timetablesSlice.reducer;

export const {
	selectById: selectTimetableById,
	selectIds: selectTimetableIds,
	selectEntities: selectTimetableEntities,
	selectAll: selectAllTimetables,
	selectTotal: selectTotalTimetables,
} = timetablesAdapter.getSelectors((state: RootState) => state.timetables);

export const selectTimetableCalltime = (
	state: RootState,
	timetableId: string
) => {
	const timetable = selectTimetableById(state, timetableId);
	return !timetable?.calltime
		? '-'
		: DateTime.fromISO(timetable.calltime).toFormat('HH:mm');
};

export const selectTimetableDTCalltime = (
	state: RootState,
	timetableId: string
) => {
	const timetable = selectTimetableById(state, timetableId);
	return !timetable?.calltime ? null : DateTime.fromISO(timetable.calltime);
};
