import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SceneFilterState {
	episode: string[]; //document ids
	location: string[]; // document ids
	character: string[]; // document ids
	search: string | undefined;
	allocationOnly: boolean;
}

const initialState: SceneFilterState = {
	episode: [],
	location: [],
	character: [],
	search: undefined,
	allocationOnly: true,
};

const scenesFilterSlice = createSlice({
	name: 'scenes-filter',
	initialState,
	reducers: {
		setSearchTerm(state, action: PayloadAction<string>) {
			state.search = action.payload;
		},
		clearSearchTerm(state) {
			state.search = undefined;
		},
		addLocationFilter(state, action: PayloadAction<string>) {
			state.location.push(action.payload);
		},
		removeLocationFilter(state, action: PayloadAction<string>) {
			state.location = state.location.filter(
				(el) => el !== action.payload
			);
		},
		clearLocationFilter(state) {
			state.location = [];
		},
		addEpisodeFilter(state, action: PayloadAction<string>) {
			state.episode.push(action.payload);
		},
		removeEpisodeFilter(state, action: PayloadAction<string>) {
			state.episode = state.episode.filter((el) => el !== action.payload);
		},
		clearEpisodeFilter(state) {
			state.episode = [];
		},
		addCharacterFilter(state, action: PayloadAction<string>) {
			state.character.push(action.payload);
		},
		removeCharacterFilter(state, action: PayloadAction<string>) {
			state.character = state.character.filter(
				(el) => el !== action.payload
			);
		},
		clearCharacterFilter(state) {
			state.character = [];
		},
		allocationOnlyFilterToggle(state) {
			state.allocationOnly = !state.allocationOnly;
		},
		resetFilter(state) {
			state = initialState;
		},
	},
});

export const {
	setSearchTerm,
	clearSearchTerm,
	addCharacterFilter,
	addEpisodeFilter,
	addLocationFilter,
	removeCharacterFilter,
	removeEpisodeFilter,
	removeLocationFilter,
	clearCharacterFilter,
	clearEpisodeFilter,
	clearLocationFilter,
	allocationOnlyFilterToggle,
} = scenesFilterSlice.actions;
export default scenesFilterSlice.reducer;
