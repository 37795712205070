import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FixMeLater } from '../../constants/FixMeLater';

interface RefsSlice {
	scenesList: FixMeLater;
}

const initialState: RefsSlice = {
	scenesList: undefined,
};

const refsSlice = createSlice({
	name: 'refs',
	initialState,
	reducers: {
		setScenesListRef(state, action: PayloadAction<FixMeLater>) {
			state.scenesList = action.payload;
		},
		resetOptions(state) {
			state = { ...initialState };
		},
	},
});

export const { setScenesListRef, resetOptions } = refsSlice.actions;
export default refsSlice.reducer;
