import React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import HelpIcon from '@mui/icons-material/Help';
import BugReportRoundedIcon from '@mui/icons-material/BugReportRounded';
const actions = [
	{
		icon: <BugReportRoundedIcon />,
		name: '피드백',
		url: 'http://pf.kakao.com/_eLxoxcb/chat',
	},
	{
		icon: <HelpIcon />,
		name: '가이드',
		url: 'https://drive.google.com/file/d/1nnuWfxNc33Pw9w1ngxzmA-NYvvBNaH86/view',
	},
	{
		icon: <QuestionAnswerIcon />,
		name: '채널',
		url: 'http://pf.kakao.com/_eLxoxcb',
	},
];

const HelpButton = () => {
	const handleClick = (url: string) => {
		window.open(
			url,
			'_blank' // <- This is what makes it open in a new window.
		);
	};
	return (
		<Box
			sx={{
				height: 320,
				position: 'absolute',
				bottom: '10px',
				right: '10px',
				flexGrow: 1,
			}}
		>
			<SpeedDial
				ariaLabel='SpeedDial basic example'
				sx={{ position: 'absolute', bottom: 16, right: 16 }}
				icon={<SpeedDialIcon />}
			>
				{actions.map((action) => (
					<SpeedDialAction
						key={action.name}
						icon={action.icon}
						tooltipTitle={action.name}
						onClick={() => {
							handleClick(action.url);
						}}
					/>
				))}
			</SpeedDial>
		</Box>
	);
};

export default HelpButton;
