import axios from 'axios';

export const firebaseApi = axios.create({
	baseURL: `https://firebasedynamiclinks.googleapis.com`,
});
export const kabinitApi = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

export const kabinitApiCred = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
});
