import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CreateProjectOptionsState {
	projectType: number | undefined;
	openForm: boolean;
}

const initialState: CreateProjectOptionsState = {
	projectType: undefined,
	openForm: false,
};

const createProjectOptionsSlice = createSlice({
	name: 'createProjectOptions',
	initialState,
	reducers: {
		setProjectType(state, action: PayloadAction<number | undefined>) {
			state.projectType = action.payload;
		},
		toggleForm(state, action: PayloadAction<boolean>) {
			state.openForm = action.payload;
		},
		resetOptions(state) {
			state = { ...initialState };
			state.openForm = false;
			return state;
		},
	},
});

export const { setProjectType, resetOptions, toggleForm } =
	createProjectOptionsSlice.actions;
export default createProjectOptionsSlice.reducer;
