import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { FixMeLater } from '../../constants/FixMeLater';
import { RootState } from '../store';

export const callsheetRowsAdapter = createEntityAdapter({
	selectId: (CallsheetRow: FixMeLater) => CallsheetRow._id,
	sortComparer: (a, b) => a.rowIndex - b.rowIndex,
});

const initialState = callsheetRowsAdapter.getInitialState();

const callsheetRowsSlice = createSlice({
	name: 'callsheet-rows',
	initialState,
	reducers: {
		addCallsheetRow: callsheetRowsAdapter.addOne,
		upsertCallsheetRow: callsheetRowsAdapter.upsertOne,
		upsertCallsheetRows: callsheetRowsAdapter.upsertMany,
		removeCallsheetRow: callsheetRowsAdapter.removeOne,
		resetCallsheetRows: callsheetRowsAdapter.removeAll,
		setAllCallsheetRows: callsheetRowsAdapter.setAll,
		updatePhase: (state, action) => {
			const { rowId, updatedPhaseData } = action.payload;
			const row = state.entities[rowId];
			if (row && row.rowType === 'phase') {
				row.phaseData = Object.assign(row.phaseData, updatedPhaseData);
				return state;
			}
		},
	},
});

export const {
	addCallsheetRow,
	upsertCallsheetRow,
	upsertCallsheetRows,
	removeCallsheetRow,
	resetCallsheetRows,
	setAllCallsheetRows,
	updatePhase,
} = callsheetRowsSlice.actions;
export default callsheetRowsSlice.reducer;

export const {
	selectById: selectCallsheetRowById,
	selectIds: selectCallsheetRowIds,
	selectEntities: selectCallsheetRowEntities,
	selectAll: selectAllCallsheetRows,
	selectTotal: selectTotalCallsheetRows,
} = callsheetRowsAdapter.getSelectors(
	(state: RootState) => state.callsheetRows
);
