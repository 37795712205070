//GENERAL
export const UNLOAD_PROJECT: string = 'UNLOAD_PROJECT';
export const SET_PAGE_TAB: string = 'SET_PAGE_TAB';

//AUTH
export const SIGN_IN: string = 'SIGN_IN';
export const SIGN_OUT: string = 'SIGN_OUT';

//BREAKDOWNS
export const FETCH_BREAKDOWNS: string = 'FETCH_BREAKDOWNS';
export const FETCH_PROJECT_MAIN_BREAKDOWN: string =
	'FETCH_PROJECT_MAIN_BREAKDOWN';
export const FETCH_BREAKDOWN_BY_ID: string = 'FETCH_BREAKDOWN_BY_ID';
export const CREATE_BREAKDOWN: string = 'CREATE_BREAKDOWN';
export const CREATE_BREAKDOWN_VERSION: string = 'CREATE_BREAKDOWN_VERSION';
export const DELETE_BREAKDOWN: string = 'DELETE_BREAKDOWN';
export const GET_BREAKDOWN_VERSIONS: string = 'GET_BREAKDOWN_VERSIONS';
export const IMPORT_SCREENPLAY_SCENES: string = 'IMPORT_SCREENPLAY_SCENES';
export const SCENE_NAME_CHANGED: string = 'SCENE_NAME_CHANGED';
export const SCENE_DELETED: string = 'SCENE_DELETED';
export const SCENE_ADDED: string = 'SCENE_ADDED';
export const SCENES_REORDERED: string = 'SCENES_REORDERED';

//CALLSHEET
export const GENERATE_CALLSHEET: string = 'GENERATE_CALLSHEET';
export const GENERATE_CALLSHEETS: string = 'GENERATE_CALLSHEETS';
export const GET_CALLSHEET_LIST: string = 'GET_CALLSHEET_LIST';
export const GET_CALLSHEET_BY_ID: string = 'GET_CALLSHEET_BY_ID';
export const APPLY_CHANGES_TO_CALLSHEET: string = 'APPLY_CHANGES_TO_CALLSHEET';
export const ASSIGN_CALL_LOCATION: string = 'ASSIGN_CALL_LOCATION';
export const UPDATE_CALLSHEET: string = 'UPDATE_CALLSHEET';
export const UPDATE_LOCATION_IN_CALLSHEET: string =
	'UPDATE_LOCATION_IN_CALLSHEET';

//CALLSHEET WIDGETS
export const ADD_TEAM_MEMO: string = 'ADD_TEAM_MEMO';
export const UPDATE_TEAM_MEMO: string = 'UPDATE_TEAM_MEMO';
export const DELETE_TEAM_MEMO: string = 'DELETE_TEAM_MEMO';
export const ADD_CONTACTS_WIDGET_ITEM: string = 'ADD_CONTACTS_WIDGET_ITEM';
export const DELETE_CONTACTS_WIDGET_ITEM: string =
	'DELETE_CONTACTS_WIDGET_ITEM';

//CHARACTERS
export const ADD_SCENE_CHARACTER: string = 'ADD_SCENE_CHARACTER';
export const CREATE_CHARACTER: string = 'CREATE_CHARACTER';
export const DELETE_SCENE_CHARACTER: string = 'DELETE_SCENE_CHARACTER';
export const DROP_SCENE_CHARACTERS: string = 'DROP_SCENE_CHARACTERS';

//CREW LIST
export const NEW_CREW_ADDED: string = 'NEW_CREW_ADDED';
export const MULTIPLE_CREW_ADDED: string = 'MULTIPLE_CREW_ADDED';
export const GET_CREW_LIST: string = 'GET_CREW_LIST';
export const UPDATE_CREW_PERMISSIONS: string = 'UPDATE_CREW_PERMISSIONS';
export const ASSIGN_MANAGERS: string = 'ASSIGN_MANAGERS';
export const ADD_NEW_TEAM: string = 'ADD_NEW_TEAM';
export const CHANGE_TEAM_NAME: string = 'CHANGE_TEAM_NAME';
export const CHANGE_TEAM_ORDER: string = 'CHANGE_TEAM_ORDER';
export const CHANGE_CREW_ROLE: string = 'CHANGE_CREW_ROLE';
export const CHANGE_CREW_TEAM: string = 'CHANGE_CREW_TEAM';
export const REMOVE_CREW: string = 'REMOVE_CREW';
export const REMOVE_MULTIPLE_CREW: string = 'REMOVE_MULTIPLE_CREW';

//CREW LIST SELECT
export const SELECT_CREW: string = 'SELECT_CREW';
export const DESELECT_CREW: string = 'DESELECT_CREW';
export const SELECT_ALL_CREW: string = 'SELECT_ALL_CREW';
export const DESELECT_ALL_CREW: string = 'DESELECT_ALL_CREW';
export const TOGGLE_CREW: string = 'TOGGLE_CREW';
export const TOGGLE_ALL_CREW: string = 'TOGGLE_ALL_CREW';
export const TOGGLE_ALL_TEAM_MEMBERS: string = 'TOGGLE_ALL_TEAM_MEMBERS';

//CREW REQUESTS
export const REMOVE_CREW_REQUEST_FROM_PROJECT: string =
	'REMOVE_CREW_REQUEST_FROM_PROJECT';
export const REMOVE_MULTIPLE_CREW_REQUEST_FROM_PROJECT: string =
	'REMOVE_MULTIPLE_CREW_REQUEST_FROM_PROJECT';
export const ADD_CREW_REQUEST_TO_PROJECT: string =
	'ADD_CREW_REQUEST_TO_PROJECT';
export const FETCH_USER_CREW_REQUESTS: string = 'FETCH_USER_CREW_REQUESTS';
export const CREATE_NEW_CREW_REQUEST: string = 'CREATE_NEW_CREW_REQUEST';
export const CONVERT_CREW_REQUEST_TO_PROJECT: string =
	'CONVERT_CREW_REQUEST_TO_PROJECT';
export const DELETE_CREW_REQUEST: string = 'DELETE_CREW_REQUEST';
export const UPDATE_CREW_REQUEST_TEAM: string = 'UPDATE_CREW_REQUEST_TEAM';
export const UPDATE_CREW_REQUEST_ROLE: string = 'UPDATE_CREW_REQUEST_ROLE';

//CREW REQUEST SELECT
export const SELECT_CREW_REQUEST: string = 'SELECT_CREW_REQUEST';
export const DESELECT_CREW_REQUEST: string = 'DESELECT_CREW_REQUEST';
export const SELECT_ALL_CREW_REQUEST: string = 'SELECT_ALL_CREW_REQUEST';
export const DESELECT_ALL_CREW_REQUEST: string = 'DESELECT_ALL_CREW_REQUEST';
export const TOGGLE_CREW_REQUEST: string = 'TOGGLE_CREW_REQUEST';
export const TOGGLE_ALL_CREW_REQUESTS: string = 'TOGGLE_ALL_CREW_REQUESTS';

//INVITE SIGNUP
export const FETCH_INVITED_PROJECT: string = 'FETCH_INVITED_PROJECT';
export const SUBMIT_INVITE_SIGNUP_DATA: string = 'SUBMIT_INVITE_SIGNUP_DATA';
export const SUBMIT_INVITE_SIGNUP: string = 'SUBMIT_INVITE_SIGNUP';
export const RESET_INVITE_SIGNUP: string = 'RESET_INVITE_SIGNUP';

//LOCATIONS
export const ASSIGN_SCENE_LOCATION: string = 'ASSIGN_SCENE_LOCATION';
export const CREATE_SCENE_LOCATION: string = 'CREATE_SCENE_LOCATION';
export const DELETE_SCENE_LOCATION: string = 'DELETE_SCENE_LOCATION';

//PAGE PRESENCE
export const USER_CONNECTED_TO_PAGE: string = 'USER_CONNECTED_TO_PAGE';
export const USER_PRESENCE_CATCH_UP: string = 'USER_PRESENCE_CATCH_UP';
export const RESET_PAGE_PRESENCE: string = 'RESET_PAGE_PRESENCE';
export const USER_DISCONNECTED_FROM_PAGE: string =
	'USER_DISCONNECTED_FROM_PAGE';
export const USER_CONNECTED_TO_PROJECT: string = 'USER_CONNECTED_TO_PROJECT';
export const USER_DISCONNECTED_FROM_PAGROJECT: string =
	'USER_DISCONNECTED_FROM_PROJECT';

//PROJECT
export const FETCH_PROJECT_BY_ID: string = 'FETCH_PROJECT_BY_ID';
export const CREATE_PROJECT: string = 'CREATE_PROJECT';
export const UPDATE_PROJECT: string = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_FROM_PROJECTS: string =
	'UPDATE_PROJECT_FROM_PROJECTS';
export const FETCH_USER_PROJECTS: string = 'FETCH_USER_PROJECTS';
export const ARCHIVE_PROJECT: string = 'ARCHIVE_PROJECT';
export const UNARCHIVE_PROJECT: string = 'UNARCHIVE_PROJECT';
export const DELETE_PROJECT: string = 'DELETE_PROJECT';
export const FETCH_USER_PERMISSIONS: string = 'FETCH_USER_PERMISSIONS';
export const GET_PROJECT_CREW_REQUESTS: string = 'GET_PROJECT_CREW_REQUESTS';
export const APPLY_TO_PROJECT: string = 'APPLY_TO_PROJECT';
export const UPDATE_USER_PERMISSIONS: string = 'UPDATE_USER_PERMISSIONS';
export const UPLOAD_MAIN_PHOTO: string = 'UPLOAD_MAIN_PHOTO';
export const SET_PROJECT_TYPE: string = 'SET_PROJECT_TYPE';
export const OPEN_PROJECT_FROM_PROJECTS: string = 'OPEN_PROJECT_FROM_PROJECTS';
export const UPSERT_PROJECT: string = 'UPSERT_PROJECT';

//SCENES
export const SCENE_INDEX_CHANGE: string = 'SCENE_INDEX_CHANGE';
export const GET_SCENE_BY_ID: string = 'GET_SCENE_BY_ID';
export const UPDATE_SCENE_ELEMENT: string = 'UPDATE_SCENE_ELEMENT';
export const UPDATE_SCENE: string = 'UPDATE_SCENE';
export const CURRENT_SCENE_NAME_CHANGED: string = 'CURRENT_SCENE_NAME_CHANGED';
export const CURRENT_SCENE_DELETED: string = 'CURRENT_SCENE_DELETED';
export const GET_FIRST_SCENE: string = 'GET_FIRST_SCENE';
export const DEACTIVATE_SCENE_FIELD: string = 'DEACTIVATE_SCENE_FIELD';
export const ACTIVATE_SCENE_FIELD: string = 'ACTIVATE_SCENE_FIELD';
export const ADD_SCENE_FIELD_ELEMENT: string = 'ADD_SCENE_FIELD_ELEMENT';
export const UPDATE_SCENE_FIELD_ELEMENT: string = 'UPDATE_SCENE_FIELD_ELEMENT';
export const DELETE_SCENE_FIELD_ELEMENT: string = 'DELETE_SCENE_FIELD_ELEMENT';
export const CURRENT_SCENE_REORDERED: string = 'CURRENT_SCENE_REORDERED';

//SCREENPLAY
export const GET_SCREENPLAY_VERSIONS: string = 'GET_SCREENPLAY_VERSIONS';
export const GET_SCREENPLAY_BY_ID: string = 'GET_SCREENPLAY_BY_ID';
export const CREATE_SCREENPLAY: string = 'CREATE_SCREENPLAY';
export const CREATE_SCREENPLAY_VERSION: string = 'CREATE_SCREENPLAY_VERSION';
export const EDIT_SCREENPLAY_CONTENT: string = 'EDIT_SCREENPLAY_CONTENT';
export const EDIT_SCREENPLAY_DETAIL: string = 'EDIT_SCREENPLAY_DETAIL';
export const DELETE_SCREENPLAY: string = 'DELETE_SCREENPLAY';
export const DELETE_SCREENPLAY_VERSION: string = 'DELETE_SCREENPLAY_VERSION';
export const INITIALIZE_SCREENPLAY: string = 'INITIALIZE_SCREENPLAY';

//SCREENPLAY SCENES LIST
export const UPDATE_SCREENWRITING_SCENES_LIST: string =
	'UPDATE_SCREENWRITING_SCENES_LIST';
export const RESET_SCREENWRITING_SCENES_LIST: string =
	'RESET_SCREENWRITING_SCENES_LIST';
export const UPDATE_SCREENWRITING_CHARACTERS_LIST: string =
	'UPDATE_SCREENWRITING_CHARACTERS_LIST';
export const RESET_SCREENWRITING_CHARACTERS_LIST: string =
	'RESET_SCREENWRITING_CHARACTERS_LIST';

//SCREENPLAY EDITOR
export const SET_SCREENPLAY_EDITOR_TYPE: string = 'SET_SCREENPLAY_EDITOR_TYPE';

//SIDEBAR
export const SET_SIDEBAR_COLLAPSE: string = 'SET_SIDEBAR_COLLAPSE';
export const TOGGLE_SIDEBAR: string = 'TOGGLE_SIDEBAR';

//TIMETABLE
export const GET_TIMETABLES: string = 'GET_TIMETABLES';
export const GET_TIMETABLE: string = 'GET_TIMETABLE';
export const ADD_FIRST_TIMETABLE: string = 'ADD_FIRST_TIMETABLE';
export const ADD_TIMETABLE: string = 'ADD_TIMETABLE';
export const DELETE_TIMETABLE: string = 'DELETE_TIMETABLE';
export const UPDATE_TIMETABLE: string = 'UPDATE_TIMETABLE';
export const UPDATE_TIMETABLE_NAME: string = 'UPDATE_TIMETABLE_NAME';
export const UPDATE_TIMETABLE_CALLTIME: string = 'UPDATE_TIMETABLE_CALLTIME';
export const GET_UNALLOCATED_SCENES: string = 'GET_UNALLOCATED_SCENES';
export const ADD_SCENE_TO_TABLE: string = 'ADD_SCENE_TO_TABLE';
export const ADD_SCENE_DATA_TO_TABLE: string = 'ADD_SCENE_DATA_TO_TABLE';
export const ADD_SCENE_TO_TABLE_WITH_DATA: string =
	'ADD_SCENE_TO_TABLE_WITH_DATA';
export const REMOVE_SCENE_FROM_TABLE: string = 'REMOVE_SCENE_FROM_TABLE';
export const MOVE_ROW: string = 'MOVE_ROW';
export const UPDATE_SCENE_FROM_TIMETABLE: string =
	'UPDATE_SCENE_FROM_TIMETABLE';
export const ADD_PHASE_TO_TABLE: string = 'ADD_PHASE_TO_TABLE';
export const UPDATE_PHASE: string = 'UPDATE_PHASE';
export const REMOVE_PHASE_FROM_TABLE: string = 'REMOVE_PHASE_FROM_TABLE';
export const FETCH_PARENT_BREAKDOWN_ID: string = 'FETCH_PARENT_BREAKDOWN_ID';

//USER
export const GET_USER_BY_ID: string = 'GET_USER_BY_ID';
export const DELETE_USER: string = 'DELETE_USER';
export const UPDATE_USER: string = 'UPDATE_USER';
export const UPDATE_USER_TOOLTIPS: string = 'UPDATE_USER_TOOLTIPS';
export const UPDATE_RECENTS: string = 'UPDATE_RECENTS';
export const UPLOAD_AVATAR: string = 'UPLOAD_AVATAR';
