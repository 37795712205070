import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { FixMeLater } from '../../constants/FixMeLater';
import { RootState } from '../store';

export const ceFilesAdapter = createEntityAdapter({
	selectId: (file: FixMeLater) => file._id,
});

const initialState = ceFilesAdapter.getInitialState();

const ceFilesSlice = createSlice({
	name: 'ce-files',
	initialState,
	reducers: {
		getFiles: ceFilesAdapter.setAll,
		addFile: ceFilesAdapter.addOne,
		addFiles: ceFilesAdapter.addMany,
		upsertFile: ceFilesAdapter.upsertOne,
		removeFile: ceFilesAdapter.removeOne,
		resetFiles: ceFilesAdapter.removeAll,
	},
});

export const {
	getFiles,
	addFile,
	addFiles,
	upsertFile,
	removeFile,
	resetFiles,
} = ceFilesSlice.actions;
export default ceFilesSlice.reducer;

export const {
	selectById: selectFileById,
	selectIds: selectFileIds,
	selectEntities: selectFileEntities,
	selectAll: selectAllFiles,
	selectTotal: selectTotalFiles,
} = ceFilesAdapter.getSelectors((state: RootState) => state.ceFiles);
