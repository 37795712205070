import { Typography } from '@mui/joy';
import { Box, Container, Grid } from '@mui/material';
import React from 'react';
import timetable from '../assets/svgs/1.svg';
import defaultApp from '../assets/svgs/2.svg';

interface Props {
	mb?: any;
}

const WebAndMobile: React.FC<Props> = ({ mb }) => {
	return (
		<Box
			sx={{
				background:
					'linear-gradient(0deg, #EFF4FF 0%, rgba(255, 255, 255, 0) 100%)',
				paddingY: '200px',
			}}
		>
			<Container>
				<Box
					sx={{
						marginBottom: mb || { xs: '100px', sm: '100px' },
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Typography
						fontSize={{ xs: '25px', sm: '30px' }}
						sx={{ mb: '20px' }}
					>
						프리프로덕션은{' '}
						<Box component='span'>
							<Typography fontWeight='xl'>
								웹으로 정교하게
							</Typography>
						</Box>
					</Typography>

					<Typography fontSize={{ xs: '25px', sm: '30px' }}>
						현장에서는{' '}
						<Box component='span'>
							<Typography fontWeight='xl'>
								앱으로 간편하게
							</Typography>
						</Box>
					</Typography>
				</Box>
				<Grid
					container
					gap={{ xs: '100px', sm: 1 }}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignContent: 'center',
					}}
				>
					<Grid
						item
						xs={12}
						sm={7.5}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center',
						}}
					>
						<img
							src={timetable}
							alt='타임테이블 이미지'
							style={{
								flexBasis: 'calc(100% - 50px)',
								maxWidth: 'calc(100% - 50px)',
								filter: 'drop-shadow(-10px 10px 10px #a4a7b0)',
								objectFit: 'contain',
							}}
						/>
					</Grid>
					<Grid
						item
						xs={8}
						sm={3.5}
						sx={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<img
							src={defaultApp}
							alt='앱 초기화면 이미지'
							style={{
								flexBasis: 'calc(100% - 80px)',
								maxWidth: 'calc(100% - 80px)',
								filter: 'drop-shadow(-10px 10px 10px #a4a7b0)',
								objectFit: 'contain',
							}}
						/>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default WebAndMobile;
