import { Typography } from '@mui/joy';
import React from 'react';

interface Props {
	children: React.ReactNode;
}

const FeatureHeader: React.FC<Props> = ({ children }) => {
	return (
		<Typography fontWeight='xl' fontSize={{ xs: '21px', sm: '25px' }}>
			{children}
		</Typography>
	);
};

export default FeatureHeader;
