import { Typography } from '@mui/joy';
import { Grid, Stack } from '@mui/material';
import React from 'react';

interface Props {
	src: string;
	alt: string;
	textArr: string[];
	index: number;
}

const StepBlock: React.FC<Props> = ({ src, alt, textArr, index }) => {
	const renderText = () => {
		return textArr.map((text) => (
			<Typography fontWeight='lg' fontSize='20px'>
				{text}
			</Typography>
		));
	};
	return (
		<Grid container sx={{ mb: 8 }}>
			<Grid item xs={12} sm={4} sx={{ mb: { xs: 3, sm: 0 } }}>
				<Typography
					textColor='kabinit.main'
					fontWeight='xl'
					fontSize='18px'
					sx={{ mb: 3 }}
				>{`step ${index + 1}`}</Typography>
				<Stack gap={1}>{renderText()}</Stack>
			</Grid>
			<Grid item xs={12} sm={8}>
				<img
					style={{
						flexBasis: 'calc(100% - 20px)',
						maxWidth: 'calc(100% - 20px)',
						filter: 'drop-shadow(-10px 10px 10px #a4a7b0)',
						objectFit: 'contain',
					}}
					src={src}
					alt={alt}
				/>
			</Grid>
		</Grid>
	);
};

export default StepBlock;
