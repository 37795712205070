import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { FixMeLater } from '../../constants/FixMeLater';
import { RootState } from '../store';

export const screenplaysAdapter = createEntityAdapter({
	selectId: (screenplay: FixMeLater) => screenplay._id,
	sortComparer: (a, b) => {
		return b.versionNo - a.versionNo;
	},
});

const initialState = screenplaysAdapter.getInitialState();

const screenplaysSlice = createSlice({
	name: 'screenplays',
	initialState,
	reducers: {
		getScreenplays: screenplaysAdapter.setAll,
		createScreenplay: screenplaysAdapter.addOne,
		upsertScreenplay: screenplaysAdapter.upsertOne,
		removeScreenplay: screenplaysAdapter.removeOne,
		resetOptions: screenplaysAdapter.removeAll,
	},
});

export const {
	getScreenplays,
	createScreenplay,
	upsertScreenplay,
	removeScreenplay,
	resetOptions,
} = screenplaysSlice.actions;
export default screenplaysSlice.reducer;

export const {
	selectById: selectScreenplayById,
	selectIds: selectScreenplayIds,
	selectEntities: selectScreenplayEntities,
	selectAll: selectAllScreenplays,
	selectTotal: selectTotalScreenplays,
} = screenplaysAdapter.getSelectors((state: RootState) => state.screenplays);
