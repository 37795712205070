import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SceneFilterState {
	permission: string[];
	team: string[];
	search: string | undefined;
}

const initialState: SceneFilterState = {
	permission: [],
	team: [],
	search: undefined,
};

const crewTeamsFilterSlice = createSlice({
	name: 'crew-teams-filter',
	initialState,
	reducers: {
		setSearchTerm(state, action: PayloadAction<string>) {
			state.search = action.payload;
		},
		clearSearchTerm(state) {
			state.search = undefined;
		},
		addPermissionFilter(state, action: PayloadAction<string>) {
			state.permission.push(action.payload);
		},
		removePermissionFilter(state, action: PayloadAction<string>) {
			state.permission = state.permission.filter(
				(el) => el !== action.payload
			);
		},
		clearPermissionFilter(state) {
			state.permission = [];
		},
		addTeamFilter(state, action: PayloadAction<string>) {
			state.team.push(action.payload);
		},
		removeTeamFilter(state, action: PayloadAction<string>) {
			state.team = state.team.filter((el) => el !== action.payload);
		},
		clearTeamFilter(state) {
			state.team = [];
		},
		resetCrewTeamFilters(state) {
			state = initialState;
		},
	},
});

export const {
	setSearchTerm,
	clearSearchTerm,
	addPermissionFilter,
	addTeamFilter,
	removePermissionFilter,
	removeTeamFilter,
	clearPermissionFilter,
	clearTeamFilter,
	resetCrewTeamFilters,
} = crewTeamsFilterSlice.actions;
export default crewTeamsFilterSlice.reducer;
