import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';

import reducers from './reducers';
import { apiSlice } from './api/api.slice';

export const store = configureStore({
	reducer: reducers,
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware({ serializableCheck: false }).concat(
			// logger,
			apiSlice.middleware
		);
	},
});

export const persistor = persistStore(store);

const storeObject = { store, persistor };

export default storeObject;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
