import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Comment } from '../../interface/comments.interface';
import { RootState } from '../store';

export const commentsAdapter = createEntityAdapter<Comment>({
	selectId: (Comment: Comment) => Comment._id,
	sortComparer: (a, b) => {
		return a.createdAt.localeCompare(b.createdAt);
	},
});

const initialState = commentsAdapter.getInitialState();

const commentsSlice = createSlice({
	name: 'comments',
	initialState,
	reducers: {
		setAllComments: commentsAdapter.setAll,
		addComment: commentsAdapter.addOne,
		upsertComment: commentsAdapter.upsertOne,
		removeComment: commentsAdapter.removeOne,
		resetComments: commentsAdapter.removeAll,
	},
});

export const {
	setAllComments,
	addComment,
	upsertComment,
	removeComment,
	resetComments,
} = commentsSlice.actions;
export default commentsSlice.reducer;

export const {
	selectById: selectCommentsById,
	selectIds: selectCommentsIds,
	selectEntities: selectCommentsEntities,
	selectAll: selectAllComments,
	selectTotal: selectTotalComments,
} = commentsAdapter.getSelectors((state: RootState) => state.comments);
