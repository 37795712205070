import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { FixMeLater } from '../../constants/FixMeLater';
import { RootState } from '../store';

export const timetableRowsAdapter = createEntityAdapter({
	selectId: (TimetableRow: FixMeLater) => TimetableRow._id,
	sortComparer: (a, b) => a.rowIndex - b.rowIndex,
});

const initialState = timetableRowsAdapter.getInitialState();

const timetableRowsSlice = createSlice({
	name: 'timetable-rows',
	initialState,
	reducers: {
		addTimetableRow: timetableRowsAdapter.addOne,
		upsertTimetableRow: timetableRowsAdapter.upsertOne,
		upsertTimetableRows: timetableRowsAdapter.upsertMany,
		removeTimetableRow: timetableRowsAdapter.removeOne,
		resetTimetableRows: timetableRowsAdapter.removeAll,
		setAllTimetableRows: timetableRowsAdapter.setAll,
		updatePhase: (state, action) => {
			const { rowId, updatedPhaseData } = action.payload;
			const row = state.entities[rowId];
			if (row && row.rowType === 'phase') {
				row.phaseData = Object.assign(row.phaseData, updatedPhaseData);
				return state;
			}
		},
		removeSceneRows: (state, action) => {
			const sceneId = action.payload;
			const sceneRows = Object.values(state.entities).filter(
				(row) => row.rowType === 'scene' && row.sceneData === sceneId
			);
			sceneRows.forEach((row) => {
				timetableRowsAdapter.removeOne(state, row._id);
			});
		},
	},
});

export const {
	addTimetableRow,
	upsertTimetableRow,
	upsertTimetableRows,
	removeTimetableRow,
	resetTimetableRows,
	setAllTimetableRows,
	updatePhase,
	removeSceneRows,
} = timetableRowsSlice.actions;
export default timetableRowsSlice.reducer;

export const {
	selectById: selectTimetableRowById,
	selectIds: selectTimetableRowIds,
	selectEntities: selectTimetableRowEntities,
	selectAll: selectAllTimetableRows,
	selectTotal: selectTotalTimetableRows,
} = timetableRowsAdapter.getSelectors(
	(state: RootState) => state.timetableRows
);
