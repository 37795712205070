import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: string = '';

const currentTimetableSlice = createSlice({
	name: 'current-timetable',
	initialState,
	reducers: {
		setCurrentTimetable(state, action: PayloadAction<string>) {
			state = action.payload;
			return state;
		},
		unsetCurrentTimetable(state) {
			state = initialState;
			return state;
		},
	},
});

export const { setCurrentTimetable, unsetCurrentTimetable } =
	currentTimetableSlice.actions;
export default currentTimetableSlice.reducer;

export const selectCurrentTimetableId = (state: RootState) => {
	return state.currentTimetable;
};
