import { Typography } from '@mui/joy';
import React from 'react';

// 캐비닛 로고 버튼
const Logo = () => {
	return (
		<Typography
			fontFamily={'Fugaz One'}
			textColor='kabinit.main'
			fontSize='25px'
		>
			Kabinit
		</Typography>
	);
};

export default Logo;
