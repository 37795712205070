import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Projects from '../features/Projects';
import PatchNotes from '../features/PatchNotes';
import { PROJECTROUTES } from './RoutesTable';
import InviteSignUp from '../features/InviteSignUp';
import AccountSettings from '../features/AccountSettings';
import NotFoundPage from '../pages/NotFoundPage';
import MaintenancePage from '../pages/MaintenancePage';

const PrivateRoutes = () => {
	const renderProjectPages = () => {
		return PROJECTROUTES.map((page) => (
			<Route
				key={page.linkUrl}
				path={page.linkUrl}
				element={page.element}
			/>
		));
	};
	return (
		<Routes>
			{/* <Route path='/' element={<Navigate to='/projects' />} />
			<Route path='/projects' element={<Projects />} />
			<Route path='/patch-notes' element={<PatchNotes />} />

			<Route path='/user' element={<AccountSettings />} />
			<Route path='/invite/:inviteCode' element={<InviteSignUp />} />
			{renderProjectPages()}
			<Route
				path='/projects/:projectId'
				element={
					<Navigate to='/projects/:projectId/timetable' replace />
				}
			/>
			<Route path='/notfound' element={<NotFoundPage />} /> */}
			<Route path='*' element={<MaintenancePage />} />
			{/* <Route path='*' element={<Navigate to='/' replace />} /> */}
		</Routes>
	);
};

export default PrivateRoutes;
