import {
	createEntityAdapter,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { CrewListItem, CrewTeam } from '../../interface/crew.interface';
import { RootState } from '../store';

export const crewTeamsAdapter = createEntityAdapter({
	selectId: (crew: CrewTeam) => crew._id,
	sortComparer: (a: CrewTeam, b: CrewTeam) => {
		return a.index - b.index;
	},
});

const initialState = crewTeamsAdapter.getInitialState();

const crewTeamsSlice = createSlice({
	name: 'crewTeams',
	initialState,
	reducers: {
		getCrewTeams: crewTeamsAdapter.setAll,
		addCrewTeam: crewTeamsAdapter.addOne,
		upsertCrewTeam: crewTeamsAdapter.upsertOne,
		upsertCrewTeams: crewTeamsAdapter.upsertMany,
		deleteCrewTeam: crewTeamsAdapter.removeOne,
		resetCrewTeams: crewTeamsAdapter.removeAll,
		addCrewToTeam: (
			state,
			action: PayloadAction<{ teamId: string; newCrew: CrewListItem }>
		) => {
			const crewTeam = state.entities[action.payload.teamId] as CrewTeam;

			crewTeam.crew.push(action.payload.newCrew);
		},
		removeCrewFromTeam: (
			state,
			action: PayloadAction<{ teamId: string; removedCrew: CrewListItem }>
		) => {
			const crewTeam = state.entities[action.payload.teamId] as CrewTeam;
			const crewIndex = crewTeam?.crew.findIndex(
				(crew) => crew._id === action.payload.removedCrew._id
			);
			crewTeam.crew.splice(crewIndex, 1);
		},
		updateCrew: (
			state,
			action: PayloadAction<{ teamId: string; updatedCrew: CrewListItem }>
		) => {
			const crewTeam = state.entities[action.payload.teamId] as CrewTeam;
			const crewIndex = crewTeam?.crew.findIndex(
				(crew) => crew._id === action.payload.updatedCrew._id
			);
			if (crewIndex < 0) {
				return;
			}
			crewTeam.crew[crewIndex] = Object.assign(
				crewTeam.crew[crewIndex],
				action.payload.updatedCrew
			);
		},
	},
});

export const {
	getCrewTeams,
	addCrewTeam,
	upsertCrewTeam,
	upsertCrewTeams,
	deleteCrewTeam,
	resetCrewTeams,
	updateCrew,
	addCrewToTeam,
	removeCrewFromTeam,
} = crewTeamsSlice.actions;
export default crewTeamsSlice.reducer;

export const {
	selectById: selectCrewTeamById,
	selectIds: selectCrewTeamIds,
	selectEntities: selectCrewTeamEntities,
	selectAll: selectAllCrewTeams,
	selectTotal: selectTotalCrewsTeams,
} = crewTeamsAdapter.getSelectors((state: RootState) => state.crewTeams);

// get team name by crewTeamid
export const selectCrewTeamNameById = (state: RootState, id: string) => {
	const crewTeam = selectCrewTeamById(state, id);
	return crewTeam?.name;
};

export const selectProjectCrewCount = (state: RootState) => {
	const allCrewTeams = selectAllCrewTeams(state);
	let totalCrewCount = 0;
	allCrewTeams.forEach((crewTeam) => {
		totalCrewCount += crewTeam.crew.length;
	});
	return totalCrewCount;
};

export const selectTeamCrewCount = (state: RootState, id: string) => {
	const crewTeam = selectCrewTeamById(state, id);
	return crewTeam?.crew.length;
};

export const selectCrewListItemById = (state: RootState, userId: string) => {
	const allCrewTeams = selectAllCrewTeams(state);
	let crewListItem: CrewListItem | undefined;
	allCrewTeams.forEach((crewTeam) => {
		crewListItem = crewTeam.crew.find(
			(crew) => (crew.user as any) === userId
		);
	});

	return crewListItem;
};
