import { Stack } from '@mui/material';
import React from 'react';
import FeatureHeader from './FeatureHeader';

interface Props {
	textArr: string[];
	sx?: any;
}

const FeatureHeaderStack: React.FC<Props> = ({ textArr, sx }) => {
	const renderFeatureHeaders = () => {
		return textArr.map((text) => (
			<FeatureHeader key={text}>{text}</FeatureHeader>
		));
	};

	return (
		<Stack
			gap={'10px'}
			sx={{
				alignSelf: { xs: 'center', sm: 'flex-start' },
				textAlign: { xs: 'center', sm: 'left' },
				...sx,
			}}
		>
			{renderFeatureHeaders()}
		</Stack>
	);
};

export default FeatureHeaderStack;
