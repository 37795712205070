import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = '';

const currentCSEditorSlice = createSlice({
	name: 'current-callsheetEditor',
	initialState,
	reducers: {
		setCurrentCallsheet(state, action: PayloadAction<string>) {
			state = action.payload;
			return state;
		},
		unsetCurrentCallsheet(state) {
			state = initialState;
			return state;
		},
	},
});

export const { setCurrentCallsheet, unsetCurrentCallsheet } =
	currentCSEditorSlice.actions;
export default currentCSEditorSlice.reducer;

export const selectCurrentCallsheetEditorId = (state: RootState) => {
	return state.currentCallsheetEditor;
};
