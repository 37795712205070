import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { FixMeLater } from '../../constants/FixMeLater';
import { RootState } from '../store';

export const ceTeamCallAdapter = createEntityAdapter({
	selectId: (teamCall: FixMeLater) => teamCall._id,
});

const initialState = ceTeamCallAdapter.getInitialState();

const ceTeamCallsSlice = createSlice({
	name: 'ce-teamCall',
	initialState,
	reducers: {
		getTeamCalls: ceTeamCallAdapter.setAll,
		addTeamCall: ceTeamCallAdapter.addOne,
		addTeamCalls: ceTeamCallAdapter.addMany,
		upsertTeamCall: ceTeamCallAdapter.upsertOne,
		removeTeamCall: ceTeamCallAdapter.removeOne,
		resetTeamCalls: ceTeamCallAdapter.removeAll,
	},
});

export const {
	getTeamCalls,
	addTeamCall,
	addTeamCalls,
	upsertTeamCall,
	removeTeamCall,
	resetTeamCalls,
} = ceTeamCallsSlice.actions;
export default ceTeamCallsSlice.reducer;

export const {
	selectById: selectTeamCallById,
	selectIds: selectTeamCallIds,
	selectEntities: selectTeamCallEntities,
	selectAll: selectAllTeamCalls,
	selectTotal: selectTotalTeamCalls,
} = ceTeamCallAdapter.getSelectors((state: RootState) => state.ceTeamCall);

export const selectTeamCallTime = (state: RootState, teamCallId: string) => {
	// get item for teamCallId
	const teamCall = selectTeamCallById(state, teamCallId);
	const calltime = teamCall?.calltime;

	if (!calltime) {
		return undefined;
	}

	return DateTime.fromISO(calltime).toFormat('HH:mm');
};
