import toast from 'react-hot-toast';
import { apiSlice } from '../api/api.slice';
import { getPatchNotes } from './patch-note.slice';

const patchNoteApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getPatchNotes: build.query({
			query: () => ({
				url: `/patchnotes/public`,
				method: 'GET',
				withCredentials: true,
			}),

			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;

					dispatch(getPatchNotes(data.data));
				} catch (err: any) {
					toast.error(err.error.data.message);
				}
			},
		}),
	}),

	overrideExisting: false,
});

export const { useGetPatchNotesQuery } = patchNoteApi;
