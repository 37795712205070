import { UNLOAD_PROJECT } from '../actionTypes';
import { AnyAction } from 'redux';
import { ProjectState } from '../../interface/project.interface';

const initial_state: Partial<ProjectState> = {};

const currentProjectReducer = (state = initial_state, action: AnyAction) => {
	switch (action.type) {
		case UNLOAD_PROJECT:
			return initial_state;

		default:
			return state;
	}
};

export default currentProjectReducer;
