import React from 'react';
import { Typography } from '@mui/joy';
import { Box, Container } from '@mui/material';
import RegistrationButton from '../components/RegistrationButton';

const WebEnd = () => {
	return (
		<Box
			sx={{
				py: 5,
				height: '400px',
				background:
					'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.14) 0%, rgba(20, 100, 255, 0) 100%), linear-gradient(0deg, #EFF4FF 0%, rgba(255, 255, 255, 0) 100%)',
			}}
		>
			<Container
				maxWidth='md'
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Typography
					fontWeight='500'
					fontSize={{ xs: '20px', sm: '26px' }}
					sx={{ mb: 1.5 }}
				>
					프리프로덕션부터 현장까지
				</Typography>
				<Typography
					fontWeight='600'
					fontSize={{ xs: '25px', sm: '40px' }}
					textAlign='center'
				>
					{`전부 `}
					<Typography textColor='kabinit.main' textAlign='center'>
						캐비닛
					</Typography>
					에 담아주세요
				</Typography>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginTop: '36px',
					}}
				>
					<RegistrationButton />
				</Box>
			</Container>
		</Box>
	);
};

export default WebEnd;
