import { Box, Container } from '@mui/material';
import React from 'react';
import image11 from '../assets/svgs/11_3.svg';
import image12 from '../assets/svgs/12.svg';
import image13 from '../assets/svgs/13.svg';
import image14 from '../assets/svgs/14.svg';
import StepBlock from '../components/StepBlock';

const pages = [
	{
		textArr: ['시나리오만 올리면', '인공지능이', '알아서 씬 정리!'],
		src: image11,
		alt: 'alt',
	},
	{
		textArr: ['촬영 스케줄은', '드래그앤 드롭으로', '간편하게!'],
		src: image12,
		alt: 'alt',
	},
	{
		textArr: ['스태프들 관리도 하고', '협업도 하고!'],
		src: image13,
		alt: 'alt',
	},
	{
		textArr: ['손쉽게 배포하고', '누가 확인했는지도 ', '알 수 있어요!'],
		src: image14,
		alt: 'alt',
	},
];

const pagesMobile = [
	{
		textArr: ['시나리오만 올리면 인공지능이 알아서 씬 정리!'],
		src: image11,
		alt: 'alt',
	},
	{
		textArr: ['촬영 스케줄은 드래그앤 드롭으로', '간편하게!'],
		src: image12,
		alt: 'alt',
	},
	{
		textArr: ['스태프들 관리도 하고', '협업도 하고!'],
		src: image13,
		alt: 'alt',
	},
	{
		textArr: ['손쉽게 배포하고누가 확인했는지도 ', '알 수 있어요!'],
		src: image14,
		alt: 'alt',
	},
];

interface Props {
	mb?: any;
}

const WebSteps: React.FC<Props> = ({ mb }) => {
	const renderSteps = () => {
		return pages.map((page, index) => (
			<StepBlock
				index={index}
				src={page.src}
				alt={page.alt}
				textArr={page.textArr}
			/>
		));
	};
	const renderStepsMobile = () => {
		return pagesMobile.map((page, index) => (
			<StepBlock
				index={index}
				src={page.src}
				alt={page.alt}
				textArr={page.textArr}
			/>
		));
	};
	return (
		<Container maxWidth='md' sx={{ mb }}>
			<Box
				sx={{
					px: 7,
					display: { xs: 'none', sm: 'flex' },
					flexDirection: 'column',
				}}
			>
				{renderSteps()}
			</Box>
			<Box
				sx={{
					px: 7,
					display: { xs: 'flex', sm: 'none' },
					flexDirection: 'column',
				}}
			>
				{renderStepsMobile()}
			</Box>
		</Container>
	);
};

export default WebSteps;
