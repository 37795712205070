import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { selectIsSignedIn } from './redux/auth/auth.slice';
import { kabinitTheme } from './styles';
import { useSelector } from 'react-redux';
import HelpButton from './components/Buttons';
import PublicRoutes from './routes/PublicRoutes';
import { CssVarsProvider } from '@mui/joy';
import { CssBaseline } from '@mui/material';
import { useAppSelector } from './hooks/reduxHooks';
import { selectGenericLoading } from './redux/generic-loading/generic-loading.slice';
import LoadingModal from './components/LoadingModal';
import AuthWrapper from './components/AuthWrapper';
import { useRefreshQuery } from './redux/auth/auth.api-slice';

const App = () => {
	const isSignedIn = useSelector(selectIsSignedIn);

	const { isSuccess, isFetching } = useRefreshQuery(
		{},
		{ skip: !isSignedIn }
	);

	const genericLoading = useAppSelector(selectGenericLoading);

	const renderAuthWrapper = () => {
		if (isSuccess && !isFetching) {
			return <AuthWrapper />;
		}
	};

	return (
		<div className='wrap'>
			<CssBaseline />
			<CssVarsProvider theme={kabinitTheme}>
				<LoadingModal open={genericLoading === 'loading'} />
				{isSignedIn ? renderAuthWrapper() : <PublicRoutes />}
			</CssVarsProvider>
			{isSignedIn && <HelpButton />}
			<Toaster />
		</div>
	);
};

export default App;
