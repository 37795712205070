import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { FixMeLater } from '../../constants/FixMeLater';
import { RootState } from '../store';

export const crewPermissionAdapter = createEntityAdapter({
	selectId: (crewPermission: FixMeLater) => {
		if (crewPermission.user) {
			return crewPermission.user._id;
		} else {
			return crewPermission.userId;
		}
	},
});

const initialState = crewPermissionAdapter.getInitialState();

const crewPermissionSlice = createSlice({
	name: 'crew-permission',
	initialState,
	reducers: {
		addCrewPermission: crewPermissionAdapter.addOne,
		upsertCrewPermission: crewPermissionAdapter.upsertOne,
		upsertCrewPermissions: crewPermissionAdapter.upsertMany,
		removeCrewPermission: crewPermissionAdapter.removeOne,
		resetCrewPermissions: crewPermissionAdapter.removeAll,
		setCrewPermission: crewPermissionAdapter.setOne,
		setAllCrewPermissions: crewPermissionAdapter.setAll,
	},
});

export const {
	addCrewPermission,
	upsertCrewPermission,
	upsertCrewPermissions,
	removeCrewPermission,
	resetCrewPermissions,
	setAllCrewPermissions,
	setCrewPermission,
} = crewPermissionSlice.actions;
export default crewPermissionSlice.reducer;

export const {
	selectById: selectCrewPermissionById,
	selectIds: selectCrewPermissionsIds,
	selectEntities: selectCrewPermissionEntities,
	selectAll: selectAllCrewPermissions,
	selectTotal: selectTotalCrewPermissions,
} = crewPermissionAdapter.getSelectors(
	(state: RootState) => state.crewPermission
);

export const selectCrewPagePermission = (
	state: RootState,
	userId: string,
	page: string
) => {
	const crewPermission = selectCrewPermissionById(state, userId);
	return crewPermission?.permissions[page];
};
