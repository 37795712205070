import PubNub from 'pubnub';
import { User } from '../../interface/auth.interface';
// import { userListener } from './user.listener';

export const initializePubNub = (userId: string) => {
	const pubnub = new PubNub({
		subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY as string,
		publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY as string,
		uuid: userId,
	});

	return pubnub;
};

export const initializePubNubUser = async (pubnub: PubNub, user: User) => {
	await pubnub.subscribe({
		channels: [`user.${user._id}`, 'test-channel'],
	});

	await pubnub.subscribe({
		channels: user.pbChannels,
	});

	// pubnub.addListener(userListener);
};

export const subscribeToProjectPage = async (
	pubnub: PubNub,
	projectId: string,
	page: string
) => {
	unsubscribeToProjectPage(pubnub);
	const channelName = `project.${projectId}.page-${page}`;

	pubnub.subscribe({
		channels: [channelName],
	});
};

export const unsubscribeToProjectPage = async (pubnub: PubNub) => {
	const subbedChannels = await pubnub.getSubscribedChannels();
	const silentIndex = subbedChannels.findIndex((channel) =>
		channel.includes('page-')
	);
	const channelName = subbedChannels[silentIndex];

	if (silentIndex > -1) {
		await pubnub.unsubscribe({ channels: [channelName] });
	}
};

export const subscribeToProject = async (pubnub: PubNub, projectId: string) => {
	unsubscribeToProject(pubnub);
	const channelName = `project.${projectId}`;

	pubnub.subscribe({
		channels: [channelName],
	});
};

export const unsubscribeToProject = async (pubnub: PubNub) => {
	const subbedChannels = await pubnub.getSubscribedChannels();
	const silentIndex = subbedChannels.findIndex((channel) =>
		channel.includes('project')
	);
	const channelName = subbedChannels[silentIndex];

	if (silentIndex > -1) {
		await pubnub.unsubscribe({ channels: [channelName] });
	}
};

export const subscribeToDocument = async (
	pubnub: PubNub,
	projectId: string,
	documentId: string
) => {
	unsubscribeToDocument(pubnub);
	const channelName = `project.${projectId}.document-${documentId}`;

	pubnub.subscribe({
		channels: [channelName],
	});
};

export const unsubscribeToDocument = async (pubnub: PubNub) => {
	const subbedChannels = await pubnub.getSubscribedChannels();
	const silentIndex = subbedChannels.findIndex((channel) =>
		channel.includes('document')
	);
	const channelName = subbedChannels[silentIndex];

	if (silentIndex > -1) {
		await pubnub.unsubscribe({ channels: [channelName] });
	}
};

export const unsubscribeToDocumentById = async (
	pubnub: PubNub,
	projectId: string,
	documentId: string
) => {
	const channelName = `project.${projectId}.document-${documentId}`;

	pubnub.unsubscribe({
		channels: [channelName],
	});
};

export const checkSenderMatch = (
	sender: string,
	userId: string | undefined
) => {
	if (!userId) {
		return false;
	}
	return sender === userId;
};
