import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: 'idle' | 'loading' | 'internal-loading' | 'skeleton' =
	'idle' as 'idle' | 'loading' | 'skeleton' | 'internal-loading';

const genericLoading = createSlice({
	name: 'generic-loading',
	initialState,
	reducers: {
		setGenericLoading(
			state,
			action: PayloadAction<
				'idle' | 'loading' | 'internal-loading' | 'skeleton'
			>
		) {
			state = action.payload;
			return state;
		},
		resetGenericLoading(state) {
			state = initialState;
			return state;
		},
	},
});

export const { setGenericLoading, resetGenericLoading } =
	genericLoading.actions;
export default genericLoading.reducer;

export const selectGenericLoading = (state: RootState) => state.genericLoading;
