import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { FixMeLater } from '../../constants/FixMeLater';
import { RootState } from '../store';

export const ceContactsWidgetAdapter = createEntityAdapter({
	selectId: (contactItem: FixMeLater) => contactItem._id,
});

const initialState = ceContactsWidgetAdapter.getInitialState();

const ceContactsWidgetSlice = createSlice({
	name: 'ceContactsWidgetAdapter',
	initialState,
	reducers: {
		getContacts: ceContactsWidgetAdapter.setAll,
		addContact: ceContactsWidgetAdapter.addOne,
		upsertContact: ceContactsWidgetAdapter.upsertOne,
		removeContact: ceContactsWidgetAdapter.removeOne,
		resetContacts: ceContactsWidgetAdapter.removeAll,
	},
});

export const {
	getContacts,
	addContact,
	upsertContact,
	removeContact,
	resetContacts,
} = ceContactsWidgetSlice.actions;
export default ceContactsWidgetSlice.reducer;

export const {
	selectById: selectContactById,
	selectIds: selectContactIds,
	selectEntities: selectContactEntities,
	selectAll: selectAllContacts,
	selectTotal: selectTotalContacts,
} = ceContactsWidgetAdapter.getSelectors(
	(state: RootState) => state.ceContactsWidget
);
