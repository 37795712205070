import { toast } from 'react-hot-toast';

export const asyncToast = async (
	asyncFunction: any,
	successMessage: string | null,
	loadingMessage?: string | null,
	failedMessage?: string | null
) => {
	let loadingToast;
	try {
		if (loadingMessage) {
			loadingToast = toast.loading(loadingMessage);
		}
		const response = await asyncFunction;
		loadingToast && toast.dismiss(loadingToast);

		successMessage && toast.success(successMessage);
		return response;
	} catch (err: any) {
		loadingToast && toast.dismiss(loadingToast);

		toast.error(failedMessage || err.error.data.message);
	}
};
