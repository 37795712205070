import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: boolean = false;

const genericToggleSlice = createSlice({
	name: 'generic-toggle',
	initialState,
	reducers: {
		toggleGenericToggle(state) {
			state = !state;
		},
		setGenericToggle(state, action: PayloadAction<boolean>) {
			state = action.payload;
		},
	},
});

export const { toggleGenericToggle, setGenericToggle } =
	genericToggleSlice.actions;
export default genericToggleSlice.reducer;

export const selectGenericToggle = (state: RootState) => state.genericToggle;
