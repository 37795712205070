import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PhaseData } from '../../interface/timetable.interface';
import { RootState } from '../store';

interface CurrentRowState {
	timetableId: string;
	rowId: string;
	phaseData: PhaseData | null;
}

const initialState: CurrentRowState = {
	timetableId: '',
	rowId: '',
	phaseData: null,
};

const currentRowSlice = createSlice({
	name: 'current-row',
	initialState,
	reducers: {
		setCurrentRow(
			state,
			action: PayloadAction<{
				timetableId: string;
				rowId: string;
				phaseData: PhaseData;
			}>
		) {
			state = action.payload;
			return state;
		},
		upsertCurrentRow(
			state,
			action: PayloadAction<{
				rowId: string;
				phaseData: Partial<PhaseData>;
			}>
		) {
			if (state.rowId === action.payload.rowId) {
				const currentState = { ...state.phaseData };
				state.phaseData = Object.assign(
					currentState,
					action.payload.phaseData
				) as any;
				return state;
			}
		},
		unsetCurrentRow(state) {
			state = initialState;
			return state;
		},
	},
});

export const { setCurrentRow, unsetCurrentRow, upsertCurrentRow } =
	currentRowSlice.actions;
export default currentRowSlice.reducer;

export const selectCurrentRow = (state: RootState) => {
	return state.currentRow;
};

export const selectCurrentRowData = (state: RootState) => {
	return state.currentRow.phaseData;
};
