import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import img5 from '../assets/svgs/Shadow.svg';
import notificationsAsset from '../assets/svgs/6.svg';
import SubHeader from '../components/SubHeader';
import FeatureHeaderStack from '../components/FeatureHeaderStack';

interface Props {
	mb: any;
}

const Realtime: React.FC<Props> = ({ mb }) => {
	return (
		<Box
			sx={{
				backgroundColor: 'kabinit.sidebarBg',
				pb: 20,
				pt: 27,
				mb,
			}}
		>
			<Container maxWidth='md'>
				<Box sx={{ px: 7 }}>
					<SubHeader sx={{ mb: 7 }}>실시간 수정</SubHeader>
					<Grid container sx={{ mb: 16 }}>
						<Grid item xs={12} sm={7}>
							<FeatureHeaderStack
								textArr={[
									'진행상황에 따라 실시간으로',
									'스케줄을 변경하고',
								]}
								sx={{
									mb: { xs: 10, sm: 0 },
								}}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={5}
							sx={{
								display: 'flex',
								justifyContent: {
									xs: 'center',
									sm: 'flex-end',
								},
								pl: { xs: 4, sm: 0 },
								pr: { xs: 2, sm: 0 },
							}}
						>
							<img
								src={img5}
								alt='앱 초기화면 이미지'
								style={{
									flexBasis: 'calc(100% - 50px)',
									maxWidth: 'calc(100% - 50px)',
									filter: 'drop-shadow(-10px 10px 10px #a4a7b0)',
									objectFit: 'contain',
								}}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid
							item
							xs={12}
							sm={5}
							sx={{
								display: { xs: 'flex', sm: 'none' },
								flexDirection: 'column',
								justifyContent: 'flex-end',
								mb: 10,
							}}
						>
							<FeatureHeaderStack
								textArr={[
									'모든 스태프들에게 ',
									'알림으로 공유해요',
								]}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={7}
							sx={{
								display: 'flex',
								justifyContent: {
									xs: 'center',
									sm: 'flex-start',
								},
							}}
						>
							<img
								src={notificationsAsset}
								alt='앱 알림'
								style={{
									flexBasis: 'calc(100% - 50px)',
									maxWidth: 'calc(100% - 50px)',
									objectFit: 'contain',
								}}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={5}
							sx={{
								display: { xs: 'none', sm: 'flex' },
								flexDirection: 'column',
								justifyContent: 'flex-end',
							}}
						>
							<FeatureHeaderStack
								textArr={[
									'모든 스태프들에게 ',
									'알림으로 공유해요',
								]}
								sx={{
									textAlign: { sm: 'right' },
									alignSelf: {
										xs: 'center',
										sm: 'flex-end',
									},
								}}
							/>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Box>
	);
};

export default Realtime;
