import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Screenplay } from '../../interface/screenplay.interface';

interface ScreenplayState {
	screenplay: Screenplay | undefined;
}

const initialState: ScreenplayState = {
	screenplay: undefined,
};

const screenplaySlice = createSlice({
	name: 'screenplay',
	initialState,
	reducers: {
		setScreenplay(state, action: PayloadAction<Screenplay>) {
			state.screenplay = { ...action.payload };
		},
		unsetScreenplay(state) {
			state.screenplay = undefined;
		},
	},
});

export const { setScreenplay, unsetScreenplay } = screenplaySlice.actions;
export default screenplaySlice.reducer;
