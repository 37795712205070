import { Typography } from '@mui/joy';
import { Box, Container, Grid } from '@mui/material';
import React from 'react';
import img3 from '../assets/svgs/3.svg';
import img4 from '../assets/svgs/4.svg';
import SubHeader from '../components/SubHeader';

const MobileIntro = () => {
	return (
		<Container maxWidth='md'>
			<Box
				sx={{
					marginY: { xs: '150px', sm: '150px' },
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				>
					<SubHeader sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
						촬영장을 위한 앱
					</SubHeader>
					<Grid container>
						<Grid
							xs={12}
							sm={6}
							sx={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Typography
								fontWeight='xl'
								fontSize={{
									xs: '22px',
									sm: '26px',
									md: '29px',
								}}
								sx={{
									marginBottom: '10px',
									justifyContent: 'center',
									alignItems: 'center',
									textAlign: { xs: 'center', sm: 'left' },
								}}
							>
								PDF와 출력물 없이
							</Typography>
							<Typography
								fontWeight='xl'
								fontSize={{
									xs: '22px',
									sm: '26px',
									md: '29px',
								}}
								sx={{
									marginBottom: '10px',
									textAlign: { xs: 'center', sm: 'left' },
								}}
							>
								촬영장의 모든 것을
							</Typography>
							<Typography
								fontWeight='xl'
								fontSize={{
									xs: '22px',
									sm: '26px',
									md: '29px',
								}}
								sx={{
									marginBottom: { xs: '80px', sm: '150px' },
									textAlign: { xs: 'center', sm: 'left' },
								}}
							>
								모바일 앱으로
							</Typography>
						</Grid>
						<Grid
							xs={12}
							sm={6}
							sx={{
								display: 'flex',
								justifyContent: {
									xs: 'center',
									sm: 'flex-end',
								},
								px: {
									xs: 8,
									sm: 0,
								},
							}}
						>
							<img
								src={img3}
								alt='개인 일정 관리 이미지'
								style={{
									flexBasis: 'calc(100% - 90px)',
									maxWidth: 'calc(100% - 90px)',
									filter: 'drop-shadow(-10px 10px 10px #a4a7b0)',
									objectFit: 'contain',
								}}
							/>
						</Grid>
						<Grid
							xs={12}
							sm={6}
							sx={{
								display: { xs: 'flex', sm: 'none' },
								flexDirection: 'column',
								justifyContent: 'flex-end',
								textAlign: 'right',
								my: 10,
							}}
						>
							<Typography
								fontWeight='md'
								textAlign='center'
								fontSize={{ xs: '20px' }}
								sx={{ marginBottom: '10px' }}
							>
								맞춤화된 일일촬영표는 물론,
							</Typography>
							<Typography
								fontWeight='md'
								textAlign='center'
								fontSize={{ xs: '20px' }}
								sx={{ marginBottom: '10px' }}
							>
								개인 촬영 일정 관리는 덤으로
							</Typography>
						</Grid>
						<Grid
							xs={12}
							sm={6}
							sx={{
								display: 'flex',
								justifyContent: {
									xs: 'center',
									sm: 'flex-start',
								},
								px: {
									xs: 8,
									sm: 0,
								},
								mt: { xs: 0, sm: -20, md: -40 },
							}}
						>
							<img
								src={img4}
								alt='앱 초기화면 이미지'
								style={{
									flexBasis: 'calc(100% - 90px)',
									maxWidth: 'calc(100% - 90px)',
									filter: 'drop-shadow(-10px 10px 10px #a4a7b0)',
									objectFit: 'contain',
								}}
							/>
						</Grid>
						<Grid
							xs={12}
							sm={6}
							sx={{
								display: { xs: 'none', sm: 'flex' },
								flexDirection: 'column',
								justifyContent: 'flex-end',
								textAlign: 'right',
							}}
						>
							<Typography
								fontWeight='md'
								fontSize={{ xs: '22px', sm: '22', md: '24px' }}
								sx={{ marginBottom: '10px' }}
							>
								맞춤화된 일일촬영표는 물론,
							</Typography>
							<Typography
								fontWeight='md'
								fontSize={{ xs: '22px', sm: '22', md: '24px' }}
								sx={{ marginBottom: '10px' }}
							>
								개인 촬영 일정 관리는 덤으로
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Container>
	);
};

export default MobileIntro;
