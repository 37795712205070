import { deepmerge } from '@mui/utils';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { experimental_extendTheme as extendMuiTheme } from '@mui/material/styles';
import { extendTheme as extendJoyTheme } from '@mui/joy/styles';

const lightPalette = {
	primary: {
		'000': '#E6EFFC',
		200: '#A1CAFF',
		plainColor: 'var(--joy-palette-kabinit-main)',
		//   plainBg: string;
		//   plainBorder: string;
		//   // hover state
		plainHoverColor: 'var(--joy-palette-kabinit-main)',
		plainHoverBorder: '#E6EFFC',
		//   plainHoverBg: string;
		//   // active state
		//   plainActiveColor: string;
		//   plainActiveBorder: string;
		//   plainActiveBg: string;
		//   // disabled state
		//   plainDisabledColor: string;
		//   plainDisabledBorder: string;
		//   plainDisabledBg: string;

		//   outlinedColor: string;
		//   outlinedBorder: string;
		//   outlinedBg: string;
		//   // hover state
		//   outlinedHoverColor: string;
		//   outlinedHoverBorder: string;
		//   outlinedHoverBg: string;
		//   // active state
		//   outlinedActiveColor: string;
		//   outlinedActiveBorder: string;
		//   outlinedActiveBg: string;
		//   // disabled state
		//   outlinedDisabledColor: string;
		//   outlinedDisabledBorder: string;
		//   outlinedDisabledBg: string;

		softColor: 'var(--joy-palette-kabinit-main)',
		// softBorder:
		softBg: '#E6EFFC',

		// softHoverColor:
		// softHoverBorder:
		// softHoverBg:

		// softActiveColor:
		// softActiveBorder:
		// softActiveBg:

		// softDisabledColor:
		// softDisabledBorder:
		// softDisabledBg:

		solidBg: 'var(--joy-palette-kabinit-main)',
		//   solidBorder: string;
		//   // hover state
		//   solidHoverColor: string;
		//   solidHoverBg: string;
		//   solidHoverBorder: string;
		//   // active state
		//   solidActiveColor: string;
		//   solidActiveBg: string;
		//   solidActiveBorder: string;
		//   // disabled state
		//   solidDisabledColor: string;
		//   solidDisabledBg: string;
		//   solidDisabledBorder: string;

		//   // override palette.text
		//   overrideTextPrimary: string;
		//   overrideTextSecondary: string;
		//   overrideTextTertiary: string;
	},
	neutral: {
		softColor: 'var(--joy-palette-kabText-light)',
		plainColor: 'var(--joy-palette-kabText-light)',
	},
	danger: {
		solidBg: '#E5493A',
		softBg: '#FFD9D6',
		softColor: '#E5493A',
		//
		500: '#FF3A30',
	},
	info: {
		plainColor: '#493D9A',
		softColor: '#493D9A',
		// softBorder:
		softBg: '#EAE6FF',

		// softHoverColor:
		// softHoverBorder:
		// softHoverBg:

		// softActiveColor:
		// softActiveBorder:
		// softActiveBg:

		// softDisabledColor:
		// softDisabledBorder:
		// softDisabledBg:
	},

	grey: {
		'000': '#F8F8F8',
		'001': '#EAECF0',
		'002': '#EAECF0',
		100: '#D3D3D3',
		200: '#BDBEBD',
		400: '#949494',
		500: '#808080',
		600: '#6A6A6A',
		700: '#545454',
		800: '#2C2C2C',
	},
	etc: {
		textSub: '#1C3659',
		iconSub: '#42526E',
	},
	BDcolor: {
		1: {
			plainColor: '#FFB2BF',
			hoverText: '#B7182B',
			hoverBg: '#FFD9DF',
			activeText: 'white',
		},
		2: {
			plainColor: '#FA3F65',
			hoverText: 'white',
			hoverBg: '#FD9FB2',
			activeText: 'white',
		},
		3: {
			plainColor: '#F96D36',
			hoverText: 'white',
			hoverBg: '#FCB69B',
			activeText: 'white',
		},
		4: {
			plainColor: '#FFF518',
			hoverText: '#EDC480',
			hoverBg: '#FFFA8B',
			activeText: 'white',
		},
		5: {
			plainColor: '#77CA52',
			hoverText: 'white',
			hoverBg: '#BBE4A8',
			activeText: 'white',
		},
		6: {
			plainColor: '#36F3E9',
			hoverText: '#82B2DB',
			hoverBg: '#9BF9F4',
			activeText: '#0564B7',
		},
		7: {
			plainColor: '#473DED',
			hoverText: 'white',
			hoverBg: '#A39EF6',
			activeText: 'white',
		},
		8: {
			plainColor: '#6F4AE2',
			hoverText: 'white',
			hoverBg: '#B7A4F1',
			activeText: 'white',
		},
		9: {
			plainColor: '#1D1747',
			hoverText: 'white',
			hoverBg: '#8E8BA3',
			activeText: 'white',
		},
		0: {
			plainColor: '#C7BFB0',
			hoverText: '#B5B5B5',
			hoverBg: '#E3DFD8',
			activeText: 'white',
		},
	},
	kabinit: {
		main: '#1464FF',
		mainBg: '#FAFBFC',
		sidebarBg: '#FAFBFC',
		lightBg: '#EAECF0',
		greyBg: '#F4F5F7',
		lightBlueBg: '#F9FBFF',
		sidebarSelected: '#E6EFFC',
		borderGeneral: '#E6E6E6',
		borderText: '#D9D9D9',
		borderLight: '#EAECF0',
		yellow: '#FFC400',
		redOrange: '#FF7452',
		confirmed: '#36B37E',
		day: '#FFC400',
		night: '#00C7E6',
		sunset: '#FF7452',
		teal: '#00C7E6',
		lightBlue: '#2684FF',
		error: '#E5493A',
		success: '#36B37E',
		navy: '#1C3659',
		darkNavy: '#334563',
		darkNavyHover: '#253758',
		grey100: '#FAFBFC',
		grey500: '#6E747B',
	},

	kabText: {
		primary: '#393939',
		light: '#54585E',
		lighter: '#6E747B',
		header: '#1C3659',
		error: '#E5493A',
		red: '#FF7452',
		navy: '#42526E',
	},
	// focusVisible: 'rgba(3, 102, 214, 0.3)',
};

export const joyTheme = extendJoyTheme({
	focus: {
		thickness: '2px',
	},
	colorSchemes: {
		light: {
			palette: lightPalette,
		},
	},
	fontFamily: {
		body: 'Noto Sans KR',
		fallback: 'sans-serif',
	},
	fontWeight: {
		xs: 100, //THIN
		sm: 300, //LIGHT
		md: 400, //REGULAR
		lg: 500, //MEDIUM
		xl: 700, //BOLD
		xl2: 900, //BLACK
	},
	fontSize: {
		xs: '0.625rem', //10
		sm: '0.75rem', //12
		md: '0.875rem', //14
		lg: '1rem', //16
		xl: '1.125rem', //18
		xl2: '1.25rem', //20
		xl3: '1.5rem', //22
	},
	components: {
		JoyFormHelperText: {
			styleOverrides: {
				root: {
					fontSize: 'var(--joy-fontSize-xs)',
					color: 'var(--joy-palette-danger-500)',
				},
			},
		},

		JoyInput: {
			styleOverrides: {
				root: {
					//
					minHeight: '32px',
					padding: '4px 8px',
					borderColor: 'var(--joy-palette-grey-100)',
					borderRadius: '5px',

					// text
					color: 'var(--joy-palette-grey-800)',
					fontFamily: 'var(--joy-fontFamily-body)',
					fontSize: 'var(--joy-fontSize-sm)',
					fontWeight: 500,

					// placeholder
					'& ::placeholder': {
						color: 'var(--joy-palette-grey-200)',
					},
				},
			},
		},
		JoySelect: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.variant === 'outlined' &&
						ownerState.color === 'primary' && {
							// select button
							borderColor: 'var(--joy-palette-grey-100)',
							borderRadius: '5px',

							padding: '4px 8px',
							minHeight: '30px',

							// text
							color: 'var(--joy-palette-grey-800)',
							fontFamily: 'var(--joy-fontFamily-body)',
							fontSize: 'var(--joy-fontSize-sm)',
							fontWeight: 500,
						}),
				}),
			},
		},
		JoyButton: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.color === 'primary' &&
						ownerState.variant === 'plain' &&
						{
							// '&.JoyButton-variantPlain': {
							// 	paddingLeft: 0,
							// 	paddingRight: 0,
							// },
							// '&:hover': {
							// 	backgroundColor: 'transparent',
							// },
						}),
					borderRadius: '3px',
					padding: '0.6rem 1rem',
					minHeight: '0',
					fontWeight: 'var(--joy-fontWeight-lg)',
					fontSize: 'var(--joy-fontSize-md)',
				}),
			},
		},
		JoyListItemButton: {
			defaultProps: {},
			styleOverrides: {
				root: {
					borderRadius: '5px',
					':hover': {
						backgroundColor: 'var(--joy-palette-primary-100)',
					},
					':active': {
						backgroundColor: 'var(--joy-palette-primary-200)',
					},
					'&.Joy-selected': {
						backgroundColor: 'var(--joy-palette-primary-100)',
					},
				},
			},
		},
		JoyChip: {
			styleOverrides: {
				root: {
					fontWeight: 'var(--joy-fontWeight-lg)',
					fontSize: 'var(--joy-fontSize-md)',
					borderRadius: '5px',
				},
			},
		},
		JoySwitch: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.variant === 'outlined' && {
						'& .JoySwitch-track': {
							border: '2px solid var(--joy-palette-kabText-lighter)',
							'&.Joy-checked': {
								border: '2px solid var(--joy-palette-primary-300)',
							},
						},
						'& .JoySwitch-thumb': {
							backgroundColor:
								'var(--joy-palette-kabText-lighter)',
							'&.Joy-checked': {
								backgroundColor:
									'var(--joy-palette-primary-400)',
							},
						},
						'--Switch-track-radius': '14px',
						'--Switch-track-width': '35px',
						'--Switch-track-height': '19px',
						'--Switch-thumb-size': '8px',
					}),
				}),
			},
		},
	},
	typography: {
		bodyXLt: {
			fontSize: 'var(--joy-fontSize-md)',
			fontWeight: 'var(--joy-fontWeight-xs)',
		},
		bodyLt: {
			fontSize: 'var(--joy-fontSize-md)',
			fontWeight: 'var(--joy-fontWeight-sm)',
		},
		bodyMd: {
			fontSize: 'var(--joy-fontSize-md)',
			fontWeight: 'var(--joy-fontWeight-md)',
		},
		bodyBld: {
			fontSize: 'var(--joy-fontSize-md)',
			fontWeight: 'var(--joy-fontWeight-lg)',
		},
		bodyEBld: {
			fontSize: 'var(--joy-fontSize-md)',
			fontWeight: 'var(--joy-fontWeight-xl)',
		},
		bodyBlk: {
			fontSize: 'var(--joy-fontSize-md)',
			fontWeight: 'var(--joy-fontWeight-xl2)',
		},
		logo: {
			fontFamily: 'Fugaz One',
			fontSize: 'var(--joy-fontSize-md)',
			color: 'var(--joy-palette-kabinit-main)',
		},
	},
});

export const muiTheme = extendMuiTheme({
	components: {
		MuiTextField: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.variant === 'outlined' && {
						'& input': {
							backgroundColor:
								'var(--joy-palette-kabinit-sidebarBg)',
							border: '1px solid',
							borderColor: 'var(--joy-palette-kabinit-mainBg)',
							borderRadius: '3px',
							fontSize: 'var(--joy-fontSize-sm)',
							padding: '0.6875rem',

							'::placeholder': {
								color: 'var(--joy-palette-kabText-lighter)',
								fontWeight: 'var(--joy-fontWeight-md)',
								opacity: 1,
							},
						},
					}),
				}),
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					'&.MuiFormHelperText-root': {
						fontSize: 'var(--joy-fontSize-xs)',
						color: 'var(--joy-palette-kabText-error)',
					},
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					background: '#fff',
					boxShadow: '0px 1px 4px rgba(56, 56, 56, 0.25)',
					borderRadius: '3px',
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					color: 'var(--joy-palette-kabText-lighter)',
					borderLeft: '3px solid #fff',
					'&:hover': {
						boxSizing: 'borderBox',
						borderLeft: '3px solid var(--joy-palette-kabinit-main)',
					},
				},
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-root': {
						padding: 0,
					},
				},
			},
		},
	},
});

export const kabinitTheme = deepmerge(muiTheme, joyTheme);
