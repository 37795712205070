import React from 'react';
import { Container, Stack } from '@mui/material';
import FeatureBlock from '../components/FeatureBlock';
import feature1 from '../assets/svgs/7.svg';
import feature2 from '../assets/svgs/8.svg';
import feature3 from '../assets/svgs/9.svg';
import feature4 from '../assets/svgs/10.svg';

import { Typography } from '@mui/joy';

interface Props {
	mb: any;
}

const MobileFeatures: React.FC<Props> = ({ mb }) => {
	return (
		<Container maxWidth='md' sx={{ mb }}>
			<Stack gap={2} sx={{ px: 7, mb: 14 }}>
				<Typography fontWeight='xl' fontSize='26px'>
					그 뿐만이 아니에요!
				</Typography>
				<Typography
					fontSize={{ xs: '23px', sm: '36px' }}
					fontWeight='lg'
				>
					<Typography textColor='kabinit.main'>캐비닛 앱</Typography>
					과 함께라면...
				</Typography>
			</Stack>
			<Stack gap={8} sx={{ px: 7 }}>
				<FeatureBlock
					header='현장 운영'
					image={{
						src: feature1,
						alt: '현장운영 이미지',
					}}
					lineOne='어떻게 진행되고 있는지'
					lineTwo='모두가 알 수 있으며'
				/>
				<FeatureBlock
					header='개인 맞춤화'
					image={{ src: feature2, alt: '현장운영 이미지' }}
					lineOne='나한테 필요한 정보부터 '
					lineTwo='쏙쏙 골라서 보고'
					left={true}
				/>
				<FeatureBlock
					header='자동 계산 & 업데이트'
					image={{
						src: feature3,
						alt: '현장운영 이미지',
					}}
					lineOne='사소한 계산에'
					lineTwo='머리 쓰지 않는 것은 물론'
				/>
				<FeatureBlock
					header='파일 업로드'
					image={{ src: feature4, alt: '현장운영 이미지' }}
					lineOne='링크나 첨부파일'
					lineTwo='찾아 헤맬 필요도 없어요'
					left={true}
				/>
			</Stack>
		</Container>
	);
};

export default MobileFeatures;
