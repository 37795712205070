import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { Location } from '../../interface/location.interface';
import { Timetable } from '../../interface/timetable.interface';
import { selectAllCallsheetRows } from '../callsheet-rows/callsheet-rows.slice';
import { RootState } from '../store';
import { selectAllTimetableRows } from '../timetable-rows/timetable-rows.slice';

export interface CallsheetGeneralState {
	_id: string | undefined;
	name: string | undefined;
	date: string | undefined;
	calltime: string | undefined;
	primaryCallLocation: Partial<Location> | undefined;
	status: 'editor' | 'distributed' | 'replaced' | 'deleted' | undefined;
	timetable: Partial<Timetable> | undefined;
	timeTilShoot: number | undefined;
}

const initialState: CallsheetGeneralState = {
	_id: undefined,
	name: undefined,
	date: undefined,
	calltime: undefined,
	primaryCallLocation: undefined,
	status: undefined,
	timetable: undefined,
	timeTilShoot: undefined,
};

const ceGeneralSlice = createSlice({
	name: 'ce-general',
	initialState,
	reducers: {
		getCallsheetGeneral(
			state,
			action: PayloadAction<CallsheetGeneralState>
		) {
			state = action.payload;
			return state;
		},
		updateCallsheetGeneral(
			state,
			action: PayloadAction<Partial<CallsheetGeneralState>>
		) {
			state = Object.assign(state, action.payload);
			return state;
		},
	},
});

export const { getCallsheetGeneral, updateCallsheetGeneral } =
	ceGeneralSlice.actions;
export default ceGeneralSlice.reducer;

export const selectCallsheetName = (state: RootState) => {
	if (state.ceGeneral.status === 'editor') {
		return state.ceGeneral?.timetable?.tableName || '새로운 회차';
	}
	return state.ceGeneral?.name || '새로운 회차';
};
export const selectCallsheetDate = (state: RootState) => {
	if (
		state.ceGeneral.status === 'editor' &&
		state.ceGeneral?.timetable?.tableDate
	) {
		return (
			DateTime.fromISO(
				state.ceGeneral?.timetable?.tableDate as string
			).toFormat('LL/dd/yyyy') || '날짜 미지정'
		);
	} else if (state.ceGeneral?.date) {
		return !state?.ceGeneral?.date
			? '날짜 미지정'
			: DateTime.fromISO(state?.ceGeneral?.date as string).toFormat(
					'LL/dd/yyyy'
			  );
	}
	return '날짜 미지정';
};

export const selectCallsheetCalltime = (state: RootState) => {
	if (state.ceGeneral.status === 'editor') {
		return !state?.ceGeneral?.timetable?.calltime
			? '-'
			: DateTime.fromISO(
					state?.ceGeneral?.timetable?.calltime as string
			  ).toFormat('HH:mm');
	} else {
		return !state?.ceGeneral?.calltime
			? '-'
			: DateTime.fromISO(state?.ceGeneral?.calltime as string).toFormat(
					'HH:mm'
			  );
	}
};

export const selectCallsheetShootingCall = (state: RootState) => {
	if (selectCallsheetIsEditor(state)) {
		const timetableCalltime = state?.ceGeneral?.timetable?.calltime;
		if (!timetableCalltime) return '-';

		const timetableRows = selectAllTimetableRows(state);

		if (timetableRows.length > 0) {
			// find first timetableRow of rowType 'scene'
			const firstSceneRow = timetableRows.find(
				(row) => row.rowType === 'scene'
			);
			if (firstSceneRow.startTime) {
				return DateTime.fromISO(
					firstSceneRow.startTime as string
				).toFormat('HH:mm');
			}
		}
	} else {
		const callsheetRows = selectAllCallsheetRows(state);
		if (callsheetRows.length > 0) {
			const firstSceneRow = callsheetRows.find(
				(row) => row.rowType === 'scene'
			);
			if (firstSceneRow.startTime) {
				return DateTime.fromISO(
					firstSceneRow.startTime as string
				).toFormat('HH:mm');
			}
		}
	}
	return '-';
};

export const selectCallsheetEndTime = (state: RootState) => {
	if (selectCallsheetIsEditor(state)) {
		const timetableCalltime = state?.ceGeneral?.timetable?.calltime;
		if (!timetableCalltime) return '-';

		const timetableRows = selectAllTimetableRows(state);

		if (timetableRows.length > 0) {
			//  add the estimated time of all rows
			const totalTime = timetableRows.reduce(
				(acc, row) => acc + row.estimatedTime,
				0
			);

			return DateTime.fromISO(timetableCalltime as string)
				.plus({ minutes: totalTime })
				.toFormat('HH:mm');
		}
	} else {
		const callsheetRows = selectAllCallsheetRows(state);
		const callsheetCalltime = state?.ceGeneral?.calltime;
		if (callsheetRows.length > 0) {
			const totalTime = callsheetRows.reduce(
				(acc, row) => acc + row.estimatedTime,
				0
			);

			return DateTime.fromISO(callsheetCalltime as string)
				.plus({ minutes: totalTime })
				.toFormat('HH:mm');
		}
	}
};

export const selectCallsheetPrimaryLocation = (state: RootState) => {
	return state.ceGeneral?.primaryCallLocation;
};
export const selectCallsheetTimetableId = (state: RootState) => {
	return state.ceGeneral?.timetable?._id || state.ceGeneral?.timetable;
};

export const selectCallsheetStatus = (state: RootState) => {
	return state.ceGeneral?.status;
};

export const selectCallsheetIsEditor = (state: RootState) => {
	return state.ceGeneral?.status === 'editor';
};
