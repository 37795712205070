import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Episode } from '../../interface/episode.interface';

interface CurrentEpisodeState {
	episodeId: string | undefined;
	episodeData: Episode | undefined;
}

const initialState: CurrentEpisodeState = {
	episodeId: undefined,
	episodeData: undefined,
};

const currentEpisodeSlice = createSlice({
	name: 'current-episode',
	initialState,
	reducers: {
		setCurrentEpisode(state, action: PayloadAction<Episode>) {
			state.episodeId = action.payload._id;
			state.episodeData = action.payload;
		},
		unsetIfCurrentEpisode(state, action: PayloadAction<string>) {
			if (state.episodeId === action.payload) {
				state.episodeId = undefined;
				state.episodeData = undefined;
			}
		},
		unsetCurrentEpisode(state) {
			state.episodeId = undefined;
			state.episodeData = undefined;
		},
	},
});

export const { setCurrentEpisode, unsetCurrentEpisode, unsetIfCurrentEpisode } =
	currentEpisodeSlice.actions;
export default currentEpisodeSlice.reducer;
