import {
	AnyAction,
	createEntityAdapter,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { Scene } from '../../interface/episode.interface';
import { RootState } from '../store';

export const sceneGroupsAdapter = createEntityAdapter({
	selectId: (sceneGroup: SceneGroup) => sceneGroup.sceneNo,
});

const initialState = sceneGroupsAdapter.getInitialState();

export interface SceneGroup {
	sceneNo: number;
	scenes: Scene[];
}

const sceneGroupsSlice = createSlice({
	name: 'scene-groups',
	initialState,
	reducers: {
		setSceneGroups: sceneGroupsAdapter.setAll,
		setEpisodeSceneGroups: (state, action) => {
			// action.payload is an array of array of scenes (scene groups)
			// e.g. [[scene1, scene2], [scene3, scene4]]
			const sceneGroups: SceneGroup[] = [];
			action.payload.forEach((sceneGroup: Scene[], index: number) => {
				let newSceneGroup: SceneGroup = {
					sceneNo: index + 1,
					scenes: sceneGroup,
				};
				sceneGroups.push(newSceneGroup);
			});
			sceneGroupsAdapter.setAll(state, sceneGroups);
		},
		addSceneGroup: sceneGroupsAdapter.addOne,
		updateSceneGroupSceneName: (
			state,
			action: PayloadAction<Partial<Scene>>
		) => {
			// identify the scene group using sceneId
			// action.payload is an object with sceneId and newScene
			let sceneGroupIds = state.ids;
			let sceneGroups = state.entities;

			let sceneGroup: any;
			//@ts-ignore
			sceneGroupIds.some((sceneGroupId: number) => {
				//@ts-ignore
				if (
					//@ts-ignore
					sceneGroups[sceneGroupId].scenes.find(
						(scene: Scene) => scene._id === action.payload._id
					)
				) {
					sceneGroup = sceneGroups[sceneGroupId];
					return true;
				}
				return false;
			});
			//  sceneGroups.find((sceneGroup: SceneGroup) =>
			// 	sceneGroup.scenes.find(
			// 		(scene: Scene) => scene._id === action.payload._id
			// 	)
			// );

			if (sceneGroup) {
				const sceneIndex = sceneGroup.scenes.findIndex(
					(scene: Scene) => scene._id === action.payload._id
				);

				sceneGroup.scenes[sceneIndex].name = action.payload?.name;
			}
			//set all as editableSceneGroup
			sceneGroupsAdapter.setAll(state, sceneGroups as any);
		},
	},
});

export const {
	setEpisodeSceneGroups,
	setSceneGroups,
	addSceneGroup,
	updateSceneGroupSceneName,
} = sceneGroupsSlice.actions;
export default sceneGroupsSlice.reducer;

export const {
	selectById: selectSceneGroupById,
	selectIds: selectSceneGroupIds,
	selectEntities: selectSceneGroupEntities,
	selectAll: selectAllSceneGroups,
	selectTotal: selectTotalSceneGroups,
} = sceneGroupsAdapter.getSelectors((state: RootState) => state.sceneGroups);
