import toast from 'react-hot-toast';
import { apiSlice } from '../api/api.slice';
import { setAllUserPermissions } from './user-permission.slice';

const permissionsApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getUserPermissions: build.query({
			query: () => ({
				url: `/auth/permissions`,
				method: 'GET',
				withCredentials: true,
			}),

			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(setAllUserPermissions(data.data));
				} catch (err: any) {
					toast.error(err.error.data.message);
				}
			},
		}),
	}),

	overrideExisting: false,
});

export const { useGetUserPermissionsQuery } = permissionsApi;
