import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BreakdownLoadingState {
	general: boolean;
	breakdown: boolean;
	description: boolean;
}

const initialState: BreakdownLoadingState | null = {
	general: false,
	breakdown: false,
	description: false,
};

const breakdownLoadingSlice = createSlice({
	name: 'breakdown-loading',
	initialState,
	reducers: {
		setBreakdownGeneralLoading(state, action: PayloadAction<boolean>) {
			//@ts-ignore
			state.general = action.payload;
			return state;
		},
		setBreakdownBreakdownLoading(state, action: PayloadAction<boolean>) {
			//@ts-ignore
			state.breakdown = action.payload;
			return state;
		},
		setBreakdownDescriptionLoading(state, action: PayloadAction<boolean>) {
			//@ts-ignore
			state.description = action.payload;
			return state;
		},
		resetBreakdownLoading(state) {
			state = initialState;
			return state;
		},
	},
});

export const {
	setBreakdownBreakdownLoading,
	setBreakdownDescriptionLoading,
	setBreakdownGeneralLoading,
	resetBreakdownLoading,
} = breakdownLoadingSlice.actions;
export default breakdownLoadingSlice.reducer;

export const selectBreakdownGeneralLoading = (state: any) =>
	state.breakdownLoading.general;
export const selectBreakdownBreakdownLoading = (state: any) =>
	state.breakdownLoading.breakdown;
export const selectBreakdownDescriptionLoading = (state: any) =>
	state.breakdownLoading.description;
