import { Typography } from '@mui/joy';
import { Box } from '@mui/material';
import React from 'react';

const MaintenancePage = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				margin: '25vh 10px 10px 10px',
				alignItems: 'center',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'baseline',
				}}
			>
				<Typography
					level='logo'
					sx={{
						fontSize: '45px',
						justifySelf: 'center',
						ml: 2,
						mr: 1,
						'&:hover': {
							cursor: 'pointer',
						},
					}}
				>
					Kabinit
				</Typography>
			</Box>

			<Box
				sx={{
					display: 'flex',
					flexGrow: 1,
					height: '100%',
					flexDirection: 'column',
				}}
			>
				<Typography
					textColor='kabText.header'
					fontSize='xl'
					fontWeight='lg'
					sx={{ mb: '1.25rem' }}
				>
					서비스 준비 중!
				</Typography>
			</Box>
		</Box>
	);
};

export default MaintenancePage;
