import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Character } from '../../interface/character.interface';
import { RootState } from '../store';

export const charactersAdapter = createEntityAdapter({
	selectId: (Character: Character) => Character._id,
	sortComparer: (a, b) => {
		return a.characterNumber - b.characterNumber;
	},
});

const initialState = charactersAdapter.getInitialState();

const charactersSlice = createSlice({
	name: 'characters',
	initialState,
	reducers: {
		setAllCharacters: charactersAdapter.setAll,
		addCharacter: charactersAdapter.addOne,
		upsertCharacter: charactersAdapter.upsertOne,
		upsertCharacters: charactersAdapter.upsertMany,
		removeCharacter: charactersAdapter.removeOne,
		resetCharacters: charactersAdapter.removeAll,
	},
});

export const {
	setAllCharacters,
	addCharacter,
	upsertCharacter,
	upsertCharacters,
	removeCharacter,
	resetCharacters,
} = charactersSlice.actions;
export default charactersSlice.reducer;

export const {
	selectById: selectCharactersById,
	selectIds: selectCharactersIds,
	selectEntities: selectCharactersEntities,
	selectAll: selectAllCharacters,
	selectTotal: selectTotalCharacters,
} = charactersAdapter.getSelectors((state: RootState) => state.characters);
