import {
	SIGN_IN,
	SIGN_OUT,
	UPDATE_USER,
	UPLOAD_AVATAR,
	UPDATE_USER_TOOLTIPS,
	SUBMIT_INVITE_SIGNUP,
	UNLOAD_PROJECT,
	UPDATE_RECENTS,
} from '../actionTypes';
import { FixMeLater } from '../../constants/FixMeLater';
import { kabinitApi, kabinitApiCred } from '../../apis';
import { InviteSignupForm } from '../../interface/auth.interface';
import toast from 'react-hot-toast';
import { AppDispatch } from '../store';
import history from '../../utils/history';
import { updateUser } from './auth.slice';

export const ACCESS_EXPIRY_TIME = 900 * 1000; // 만료 시간 (15분 밀리 초로 표현)
// const REFRESH_EXPIRY_TIME = 24 * 3600 * 7 * 1000; // 만료 시간 (1주 밀리 초로 표현)
// ACCESS_EXPIRY_TIME - 60000
export const onSilentRefresh = () => async (dispatch: AppDispatch) => {
	try {
		const response = await kabinitApiCred.post('/auth/refresh');
		dispatch(updateUser(response.data.data));
		setTimeout(() => {
			dispatch(onSilentRefresh());
		}, ACCESS_EXPIRY_TIME - 60000);
	} catch {
		dispatch(forceSignOut());
		history.push('/');
		toast('일정 시간이 지나 로그아웃 되었습니다. 다시 로그인 해주세요.');
	}
};

export const inviteSignUp =
	(formData: InviteSignupForm) => async (dispatch: AppDispatch) => {
		const { passwordConfirmation, ...userData } = formData;
		try {
			const response = await kabinitApiCred.post(
				'/crew-request/invite/signup',
				userData
			);
			//TODO: check refresh
			if (response.status === 201) {
				setTimeout(() => {
					// dispatch(onSilentRefresh());
				}, ACCESS_EXPIRY_TIME - 60000);

				dispatch({ type: SIGN_IN, payload: response.data });
				dispatch({ type: SUBMIT_INVITE_SIGNUP });

				toast.success('참여 신청 완료!');
			}
		} catch (err: FixMeLater) {
			toast.error(err.response.data.message);
		}
	};

export const inviteSignIn =
	(email: string, password: string, inviteCode: string) =>
	async (dispatch: AppDispatch) => {
		try {
			const response = await kabinitApiCred.post(
				'/crew-request/invite/signin',
				{
					email,
					password,
					inviteCode,
				}
			);

			if (response.status === 201) {
				setTimeout(() => {
					// dispatch(onSilentRefresh());
				}, ACCESS_EXPIRY_TIME - 60000);

				dispatch({ type: SIGN_IN, payload: response.data });
				dispatch({ type: SUBMIT_INVITE_SIGNUP });
			}
		} catch (err: any) {
			toast.error(err.response.data.message);
		}
	};

export const signOut = () => async (dispatch: AppDispatch) => {
	await kabinitApiCred.post('/auth/signout');
	// userSocket.emit('leaveUserRoom');
	dispatch({ type: UNLOAD_PROJECT });
	dispatch({
		type: SIGN_OUT,
	});
};

export const forceSignOut = () => async (dispatch: AppDispatch) => {
	await kabinitApi.post('/auth/forcesignout');

	dispatch({
		type: SIGN_OUT,
	});
};

export const uploadAvatar =
	(file: FixMeLater) => async (dispatch: AppDispatch) => {
		try {
			const response = await kabinitApiCred.post('/auth/avatar', file);
			const avatarData = response.data;
			dispatch({ type: UPLOAD_AVATAR, payload: avatarData });
		} catch (err: FixMeLater) {
			toast.error(err.response.data.message);
		}
	};

export const deleteAvatar = () => async (dispatch: AppDispatch) => {
	try {
		await kabinitApiCred.delete(`/auth/avatar`);
		const attrs = { avatar: undefined };
		dispatch({ type: UPDATE_USER, payload: attrs });
	} catch (err: FixMeLater) {
		toast.error(err.response.data.message);
	}
};

export const getAvatar = () => async (dispatch: AppDispatch) => {
	try {
		const response = await kabinitApiCred.get(`/auth/avatar`);
		const avatarData = response.data;
		const attrs = { avatar: avatarData };
		dispatch({ type: UPDATE_USER, payload: attrs });
	} catch (err: FixMeLater) {
		toast.error(err.response.data.message);
	}
};

export const updateUserTooltips =
	(tooltips: FixMeLater) => async (dispatch: AppDispatch) => {
		const response = await kabinitApiCred.patch(
			'/auth/user/tooltips',
			tooltips
		);
		const userData = response.data;
		dispatch({ type: UPDATE_USER_TOOLTIPS, payload: userData });
	};

export const getUserRecents = () => async (dispatch: AppDispatch) => {
	const response = await kabinitApiCred.get(`/auth/recents`);
	dispatch({ type: UPDATE_RECENTS, payload: response.data.recents });
};
