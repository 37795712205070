import {
	createEntityAdapter,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { FixMeLater } from '../../constants/FixMeLater';
import { RootState } from '../store';

export const episodesAdapter = createEntityAdapter({
	selectId: (episode: FixMeLater) => episode._id,
	sortComparer: (a, b) => {
		return a.episodeNo - b.episodeNo;
	},
});

const initialState = episodesAdapter.getInitialState();

const episodesSlice = createSlice({
	name: 'episodes',
	initialState,
	reducers: {
		getEpisodes: episodesAdapter.setAll,
		createEpisode: episodesAdapter.addOne,
		upsertEpisode: episodesAdapter.upsertOne,
		removeEpisode: episodesAdapter.removeOne,
		resetEpisodes: episodesAdapter.removeAll,
		addSceneToEpisode: (state, action: PayloadAction<FixMeLater>) => {
			state.entities[action.payload.episodeId].scenes.push(
				action.payload.sceneId
			);
		},
		addScreenplayToEpisode: (state, action: PayloadAction<FixMeLater>) => {
			state.entities[action.payload.episodeId].screenplays.push(
				action.payload.screenplay
			);
		},
	},
});

export const {
	getEpisodes,
	createEpisode,
	upsertEpisode,
	removeEpisode,
	resetEpisodes,
	addSceneToEpisode,
	addScreenplayToEpisode,
} = episodesSlice.actions;
export default episodesSlice.reducer;

export const {
	selectById: selectEpisodeById,
	selectIds: selectEpisodeIds,
	selectEntities: selectEpisodeEntities,
	selectAll: selectAllEpisodes,
	selectTotal: selectTotalEpisodes,
} = episodesAdapter.getSelectors((state: RootState) => state.episodes);
