import { AnyAction, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import authReducer from './auth/auth.slice';
import currentProjectReducer from './current-project/current-project.reducer';
import projectsReducer from './projects/project.slice';
import createProjectOptionsReducer from './create-project/create-project.slice';
import currentEpisodeReducer from './current-episode/current-episode.slice';
import episodesReducer from './episodes/episodes.slice';
import { apiSlice } from './api/api.slice';
import authReducer from './auth/auth.slice';
import scenesReducer from './scenes/scenes.slice';
import refsReducer from './refs/refs.slice';
import screenplayReducer from './screenplay/screenplay.slice';
import timetablesReducer from './timetables/timetables.slice';
import currentTimetableReducer from './current-timetable/current-timetable.slice';
import scenesFilters from './scenes-filter/scenes-filter.slice';
import currentSceneReducer from './current-scene/current-scene.slice';
import locationsReducer from './locations/locations.slice';
import charactersReducer from './characters/characters.slice';
import commentsReducer from './comments/comments.slice';
import crewTeamsReducer from './crew-team/crewTeams.slice';
import crewTeamsFilterReducer from './crew-teams-filter/crew-teams-filter.slice';
import isDraggingReducer from './is-dragging/is-dragging.slice';
import crewRequestsReducer from './crew-requests/crewRequests.slice';
import userRequestsReducer from './user-requests/userRequests.slice';
import versionManagersReducer from './version-manager/version-manager.slice';
import currentCallsheetEditorReducer from './current-callsheetEditor/current-callsheetEditor.slice.ts';
import ceContactsWidgetReducer from './ce-contacts-widget/ce-contacts-widget.slice';
import ceChecklistWidgetReducer from './ce-checklist-widget/ce-checklist-widget.slice';
import screenplaysReducer from './screenplay/screenplays.slice';
import timetableRowsReducer from './timetable-rows/timetable-rows.slice';
import ceGeneralReducer from './ce-general/ce-general.slice';
import genericToggleReducer from './generic-toggle/generic-toggle.slice';
import currentCrewReducer from './current-crew/current-crew.slice';
import genericLoadingReducer from './generic-loading/generic-loading.slice';
import currentLocationReducer from './current-location/current-location.slice';
import ceFilesReducer from './ce-files/ce-files.slice';
import ceLocationsReducer from './ce-locations/ce-locations.slice';
import ceTeamCallReducer from './ce-teamCall/ce-teamCall.slice';
import inviteProjectReducer from './invite-project/invite-project.slice';
import userPermissionReducer from './user-permission/user-permission.slice';
import crewPermissionReducer from './crew-permission/crew-permission.slice';
import patchNoteReducer from './patch-note/patch-note.slice';
import currentRowReducer from './current-row/current-row.slice';
import callsheetRowsReducer from './callsheet-rows/callsheet-rows.slice';
import breakdownsReducer from './breakdown/breakdown.slice';
import breakdownCategoryReducer from './breakdown-category/breakdown-category.slice';
import uiReducer from './ui/ui.slice';
import breakdownItemSelectReducer from './breakdown-item-select/breakdown-item-select.slice';
import sceneGroupsReducer from './scene-groups/scene-groups.slice';
import breakdownLoadingReducer from './breakdown-loading/breakdown-category.slice';
const persistConfig = {
	key: 'root',
	storage: storage,
	whitelist: [
		'auth',
		// 'currentProject',
		// 'inviteProject',
		'userPermission',
		'ui',
		// 'breakdown',
		// 'scene',
		// 'timetables',
		// 'timetableCurrent',
		// 'timetableUnallocated',
		// 'projectCrewList',
		// 'screenwritingScenesList',
	],
};

export const rootReducer = combineReducers({
	auth: authReducer,
	breakdowns: breakdownsReducer,
	breakdownCategory: breakdownCategoryReducer,
	breakdownItemSelect: breakdownItemSelectReducer,
	breakdownLoading: breakdownLoadingReducer,
	// callsheet: callsheetReducer,
	// callsheetList: callsheetListReducer,
	callsheetRows: callsheetRowsReducer,
	ceChecklistWidget: ceChecklistWidgetReducer,
	ceContactsWidget: ceContactsWidgetReducer,
	ceFiles: ceFilesReducer,
	ceGeneral: ceGeneralReducer,
	ceLocations: ceLocationsReducer,
	ceTeamCall: ceTeamCallReducer,
	characters: charactersReducer,
	comments: commentsReducer,
	crewTeams: crewTeamsReducer,
	crewTeamsFilter: crewTeamsFilterReducer,
	currentCallsheetEditor: currentCallsheetEditorReducer,
	currentCrew: currentCrewReducer,
	currentEpisode: currentEpisodeReducer,
	currentLocation: currentLocationReducer,
	currentProject: currentProjectReducer,
	currentScene: currentSceneReducer,
	currentRow: currentRowReducer,
	currentTimetable: currentTimetableReducer,
	createProjectOptions: createProjectOptionsReducer,
	crewRequests: crewRequestsReducer,
	episodes: episodesReducer,
	genericLoading: genericLoadingReducer,
	genericToggle: genericToggleReducer,
	locations: locationsReducer,
	// crewRequests: crewRequestsReducer,
	// pagePresence: pagePresenceReducer,
	// pageTabs: pageTabsReducer,
	inviteProject: inviteProjectReducer,
	isDragging: isDraggingReducer,
	patchNote: patchNoteReducer,
	projects: projectsReducer,
	// projectCrewList: projectCrewListReducer,
	// projectCLSelected: ProjectCLSelectedReducer,
	// projectCR: ProjectCRReducer,
	// projectCRSelected: ProjectCRSelectedReducer,
	// screenplay: screenplayReducer,
	// screenwritngScenesList: screenwritingScenesListReducer,
	// screenwritingEditor: screenwritingEditorReducer,
	// scene: sceneReducer,
	sceneGroups: sceneGroupsReducer,
	scenes: scenesReducer,
	scenesFilters: scenesFilters,
	screenplay: screenplayReducer,
	screenplays: screenplaysReducer,
	refs: refsReducer,
	timetables: timetablesReducer,
	timetableRows: timetableRowsReducer,
	crewPermission: crewPermissionReducer,
	ui: uiReducer,
	userPermission: userPermissionReducer,
	userRequests: userRequestsReducer,
	versionManagers: versionManagersReducer,

	// timetableCurrent: timetableCurrentReducer,
	// timetableUnallocated: timetableUnallocatedReducer,
	[apiSlice.reducerPath]: apiSlice.reducer,
});

export default persistReducer(persistConfig, rootReducer);

export const resettableRootReducer = (
	state: ReturnType<typeof rootReducer> | undefined,
	action: AnyAction
) => {
	if (action.type === 'store/reset') {
		// console.log('sup');

		return rootReducer(undefined, action);
	}
	return rootReducer(state, action);
};
