import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface UIState {
	sidebar: boolean;
	bdScenesList: boolean;
}

const initialState: UIState = {
	sidebar: true,
	bdScenesList: true,
};

const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		setSidebar: (state, action: PayloadAction<boolean>) => {
			state.sidebar = action.payload;
			return state;
		},
		setBreakdownScenesList: (state, action: PayloadAction<boolean>) => {
			state.bdScenesList = action.payload;
			return state;
		},
	},
});

export const { setSidebar, setBreakdownScenesList } = uiSlice.actions;
export default uiSlice.reducer;

export const selectSidebarToggle = (state: RootState) => {
	return state.ui.sidebar;
};

export const selectBreakdownScenesListToggle = (state: RootState) => {
	return state.ui.bdScenesList;
};
