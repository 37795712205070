import { PubNubConsumer, PubNubProvider } from 'pubnub-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { initializePubNub, initializePubNubUser } from '../apis/pubnub';
import { userListener } from '../apis/pubnub/user.listener';
import { User } from '../interface/auth.interface';
import { selectUser } from '../redux/auth/auth.slice';
import { useGetPatchNotesQuery } from '../redux/patch-note/patch-note.api-slice';
import { useGetUserPermissionsQuery } from '../redux/user-permission/user-permission.api-slice';
import PrivateRoutes from '../routes/PrivateRoutes';

const AuthWrapper = () => {
	const user = useSelector(selectUser) as User;

	const pubnub = initializePubNub(user._id as string);
	pubnub.addListener(userListener);

	const { isSuccess } = useGetUserPermissionsQuery(
		{},
		{
			refetchOnMountOrArgChange: true,
		}
	);
	const {} = useGetPatchNotesQuery({});
	return (
		<PubNubProvider client={pubnub}>
			<PubNubConsumer>
				{(client) => {
					initializePubNubUser(client, user);
					return <PrivateRoutes />;
				}}
			</PubNubConsumer>
		</PubNubProvider>
	);
};

export default AuthWrapper;
