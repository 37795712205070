import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Location } from '../../interface/location.interface';
import { RootState } from '../store';

export const locationsAdapter = createEntityAdapter({
	selectId: (Locations: Location) => Locations._id,
	sortComparer: (a, b) => {
		return a.realName.localeCompare(b.realName);
	},
});

const initialState = locationsAdapter.getInitialState();

const locationsSlice = createSlice({
	name: 'locations',
	initialState,
	reducers: {
		setAllLocations: locationsAdapter.setAll,
		addLocation: locationsAdapter.addOne,
		upsertLocation: locationsAdapter.upsertOne,
		removeLocation: locationsAdapter.removeOne,
		resetLocations: locationsAdapter.removeAll,
	},
});

export const {
	setAllLocations,
	addLocation,
	upsertLocation,
	removeLocation,
	resetLocations,
} = locationsSlice.actions;
export default locationsSlice.reducer;

export const {
	selectById: selectLocationsById,
	selectIds: selectLocationsIds,
	selectEntities: selectLocationsEntities,
	selectAll: selectAllLocations,
	selectTotal: selectTotalLocations,
} = locationsAdapter.getSelectors((state: RootState) => state.locations);
