import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { FixMeLater } from '../../constants/FixMeLater';
import { CallsheetVersionManager } from '../../interface/version-manager.interface';
import { RootState } from '../store';

export const versionManagerAdapter = createEntityAdapter({
	selectId: (versionManager: FixMeLater) => versionManager._id,
});

const initialState = versionManagerAdapter.getInitialState();

const versionManagerSlice = createSlice({
	name: 'version-manager',
	initialState,
	reducers: {
		getVersionManagers: versionManagerAdapter.setAll,
		createVersionManager: versionManagerAdapter.addOne,
		upsertVersionManager: versionManagerAdapter.upsertOne,
		deleteVersionManager: versionManagerAdapter.removeOne,
		resetVersionManagers: versionManagerAdapter.removeAll,
	},
});

export const {
	getVersionManagers,
	createVersionManager,
	upsertVersionManager,
	deleteVersionManager,
	resetVersionManagers,
} = versionManagerSlice.actions;
export default versionManagerSlice.reducer;

export const {
	selectById: selectVersionManagerById,
	selectIds: selectVersionManagerIds,
	selectEntities: selectVersionManagerEntities,
	selectAll: selectAllVersionManagers,
	selectTotal: selectTotalVersionManagers,
} = versionManagerAdapter.getSelectors(
	(state: RootState) => state.versionManagers
);

// get version using callsheet id
export const selectVersionByCallsheetId = (
	state: RootState,
	versionManagerId: string,
	callsheetId: string
) => {
	const versionManager = selectVersionManagerById(
		state,
		versionManagerId
	) as CallsheetVersionManager;
	let version;

	if (typeof callsheetId === 'string') {
		version = versionManager?.versions.find(
			(version) => version.callsheet._id === callsheetId
		);
	} else {
		version = versionManager?.versions.find(
			(version) => version.callsheet === callsheetId
		);
	}
	return version;
};

export const selectVersionStatus = (
	state: RootState,
	versionManagerId: string,
	callsheetId: string
) => {
	const versionManager = selectVersionManagerById(
		state,
		versionManagerId
	) as CallsheetVersionManager;
	let version;

	if (typeof callsheetId === 'string') {
		version = versionManager?.versions.find(
			(version) => version.callsheet._id === callsheetId
		);
	} else {
		version = versionManager?.versions.find(
			(version) => version.callsheet === callsheetId
		);
	}
	return version?.status;
};
