import { Button } from '@mui/joy';
import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import Logo from '../Logo';

const Header = () => {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate('/auth');
	};
	return (
		<Box
			sx={{
				position: 'fixed',
				backgroundColor: 'white',
				zIndex: 100,
				top: 0,
				left: 0,
				width: '100vw',
				display: 'flex',
				justifyContent: 'space-between',
				boxShadow: '0px 2px 32px -10px rgba(28, 54, 89, 0.2)',
				p: 2,
			}}
		>
			<Logo />
			<Button onClick={handleClick}>로그인</Button>
		</Box>
	);
};

export default Header;
