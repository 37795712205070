import {
	createEntityAdapter,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { CrewRequest } from '../../interface/crew.interface';
import { RootState } from '../store';

export const crewRequestAdapter = createEntityAdapter({
	selectId: (crew: CrewRequest) => crew._id,
});

const initialState = crewRequestAdapter.getInitialState();

const crewRequestSlice = createSlice({
	name: 'crewRequests',
	initialState,
	reducers: {
		getCrewRequests: crewRequestAdapter.setAll,
		addCrewRequest: crewRequestAdapter.addOne,
		upsertCrewRequest: crewRequestAdapter.upsertOne,
		upsertCrewRequests: crewRequestAdapter.upsertMany,
		deleteCrewRequest: crewRequestAdapter.removeOne,
		resetCrewRequests: crewRequestAdapter.removeAll,
		deleteCrewRequestWithUserId: (state, action: PayloadAction<string>) => {
			let deleteId = undefined;
			state.ids.some((requestId) => {
				//@ts-ignore
				if (state?.entities[requestId].user === action.payload) {
					deleteId = requestId;
					return true;
				}
				return false;
			});
			if (deleteId) {
				deleteCrewRequest(deleteId);
			}
		},
	},
});

export const {
	getCrewRequests,
	addCrewRequest,
	upsertCrewRequest,
	upsertCrewRequests,
	deleteCrewRequest,
	resetCrewRequests,
	deleteCrewRequestWithUserId,
} = crewRequestSlice.actions;
export default crewRequestSlice.reducer;

export const {
	selectById: selectCrewRequestById,
	selectIds: selectCrewRequestIds,
	selectEntities: selectCrewRequestEntities,
	selectAll: selectAllCrewRequests,
	selectTotal: selectTotalCrewsRequests,
} = crewRequestAdapter.getSelectors((state: RootState) => state.crewRequests);
