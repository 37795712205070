import { Toaster } from 'react-hot-toast';
import Footer from './components/Footer';
import Header from './components/Header';
import Intro from './features/Intro';
import MobileFeatures from './features/MobileFeatures';
import MobileIntro from './features/MobileIntro';
import Realtime from './features/Realtime';
import WebAndMobile from './features/WebAndMobile';
import WebEnd from './features/WebEnd';
import WebFeatures from './features/WebFeatures';
import WebIntro from './features/WebIntro';
import WebSteps from './features/WebSteps';

function LandingPage() {
	return (
		<div>
			<Header />
			{/* <Container sx={{ mt: 10 }}> */}
			<Intro mb={{ xs: 0, sm: 20 }} />
			<WebAndMobile mb={20} />
			<MobileIntro />
			<Realtime mb={20} />
			<MobileFeatures mb={1} />
			<WebIntro mb={20} />
			<WebFeatures mb={25} />
			<WebSteps mb={20} />
			<WebEnd />
			<Footer />
			{/* </Container> */}
			<Toaster />
		</div>
	);
}

export default LandingPage;
