import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IsDraggingSlice {
	isDragging: string | undefined;
}

const initialState: IsDraggingSlice = {
	isDragging: undefined,
};

const isDraggingSlice = createSlice({
	name: 'is-dragging',
	initialState,
	reducers: {
		toggleDragging(state, action: PayloadAction<string | undefined>) {
			state.isDragging = action.payload;
		},
		resetDragging(state) {
			state = { ...initialState };
		},
	},
});

export const { toggleDragging, resetDragging } = isDraggingSlice.actions;
export default isDraggingSlice.reducer;
