import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface ItemSelectState {
	breakdownId: string | null;
	type: string;
	itemIds: string[];
}

interface ItemSelectPayload {
	breakdownId: string;
	type: string;
	itemId: string;
}

const initialState: ItemSelectState = {
	breakdownId: null,
	type: '',
	itemIds: [],
};

const breakdownItemSelect = createSlice({
	name: 'breakdown-item-select',
	initialState,
	reducers: {
		selectItem(state, action: PayloadAction<ItemSelectPayload>) {
			// check if breakdownId is the same as the current breakdownId
			if (state.breakdownId !== action.payload.breakdownId) {
				// if not, set the breakdownId and clear the itemIds
				state.breakdownId = action.payload.breakdownId;
				state.type = action.payload.type;
				state.itemIds = [];
			}
			// if yes, check if itemIds in payload is in the itemIds array

			if (state.itemIds.includes(action.payload.itemId)) {
				// if yes, remove it
				state.itemIds = state.itemIds.filter(
					(itemId) => itemId !== action.payload.itemId
				);
			} else {
				state.itemIds.push(action.payload.itemId);
			}

			return state;
		},
		setSelectedItems(state, action: PayloadAction<ItemSelectState>) {
			state = action.payload;
			return state;
		},
		clearSelectedItems(state) {
			state = initialState;
			return state;
		},
	},
});

export const { selectItem, setSelectedItems, clearSelectedItems } =
	breakdownItemSelect.actions;
export default breakdownItemSelect.reducer;

export const selectSelectedBreakdownItems = (state: RootState) => {
	return state.breakdownItemSelect.itemIds;
};

export const selectSelectedBreakdownItemsParent = (state: RootState) => {
	return state.breakdownItemSelect.breakdownId;
};

export const selectCurrentTypeIsSelected = (state: RootState, type: string) => {
	return (
		state.breakdownItemSelect.type === type &&
		state.breakdownItemSelect.itemIds.length > 0
	);
};
