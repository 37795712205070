import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { FixMeLater } from '../../constants/FixMeLater';
import { RootState } from '../store';

export const userPermissionAdapter = createEntityAdapter({
	selectId: (UserPermissions: FixMeLater) => UserPermissions.projectId,
});

const initialState = userPermissionAdapter.getInitialState();

const userPermissionSlice = createSlice({
	name: 'user-permission',
	initialState,
	reducers: {
		addUserPermission: userPermissionAdapter.addOne,
		upsertUserPermission: userPermissionAdapter.upsertOne,
		upsertUserPermissions: userPermissionAdapter.upsertMany,
		removeUserPermission: userPermissionAdapter.removeOne,
		resetUserPermissions: userPermissionAdapter.removeAll,
		setAllUserPermissions: userPermissionAdapter.setAll,
	},
});

export const {
	addUserPermission,
	upsertUserPermission,
	upsertUserPermissions,
	removeUserPermission,
	resetUserPermissions,
	setAllUserPermissions,
} = userPermissionSlice.actions;
export default userPermissionSlice.reducer;

export const {
	selectById: selectUserPermissionById,
	selectIds: selectUserPermissionsIds,
	selectEntities: selectUserPermissionEntities,
	selectAll: selectAllUserPermissions,
	selectTotal: selectTotalUserPermissions,
} = userPermissionAdapter.getSelectors(
	(state: RootState) => state.userPermission
);
