import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { FixMeLater } from '../../constants/FixMeLater';
import { RootState } from '../store';

export const ceLocationsAdapter = createEntityAdapter({
	selectId: (CallsheetLocation: FixMeLater) => CallsheetLocation._id,
});

const initialState = ceLocationsAdapter.getInitialState();

const ceCallsheetLocationsSlice = createSlice({
	name: 'ce-locations',
	initialState,
	reducers: {
		getCallsheetLocations: ceLocationsAdapter.setAll,
		addCallsheetLocation: ceLocationsAdapter.addOne,
		addCallsheetLocations: ceLocationsAdapter.addMany,
		upsertCallsheetLocation: ceLocationsAdapter.upsertOne,
		removeCallsheetLocation: ceLocationsAdapter.removeOne,
		resetCallsheetLocations: ceLocationsAdapter.removeAll,
	},
});

export const {
	getCallsheetLocations,
	addCallsheetLocation,
	addCallsheetLocations,
	upsertCallsheetLocation,
	removeCallsheetLocation,
	resetCallsheetLocations,
} = ceCallsheetLocationsSlice.actions;
export default ceCallsheetLocationsSlice.reducer;

export const {
	selectById: selectCallsheetLocationById,
	selectIds: selectCallsheetLocationIds,
	selectEntities: selectCallsheetLocationEntities,
	selectAll: selectAllCallsheetLocations,
	selectTotal: selectTotalCallsheetLocations,
} = ceLocationsAdapter.getSelectors((state: RootState) => state.ceLocations);
