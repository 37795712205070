import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: string = '';

const currentLocation = createSlice({
	name: 'current-location',
	initialState,
	reducers: {
		setCurrentLocation(state, action: PayloadAction<string>) {
			state = action.payload || '';
			return state;
		},
		unsetCurrentLocation(state) {
			state = '';
			return state;
		},
	},
});

export const { setCurrentLocation, unsetCurrentLocation } =
	currentLocation.actions;
export default currentLocation.reducer;

export const selectCurrentLocationId = (state: RootState) => {
	return state.currentLocation;
};
