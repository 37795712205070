import { CircularProgress } from '@mui/joy';
import { Backdrop } from '@mui/material';
import React from 'react';

interface Props {
	open: boolean;
	handleClose?: () => void;
}

const LoadingModal: React.FC<Props> = ({ open, handleClose }) => {
	return (
		<Backdrop
			sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={open}
			onClick={handleClose}
		>
			<CircularProgress />
		</Backdrop>
	);
};

export default LoadingModal;
